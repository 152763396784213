/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useActiveCompanyCode } from './use_active_company_code';
import { useAppHistory } from './use_app_history';
import { CancellablePromise } from '../types/project.types';
import type { CompanyCallLogStore } from '../types/call_logs.types';

type TeamCallLogFiltersConfig = {
    requestGroupNames?: string[];
    requestSourceCodes?: string[];
    searchStringFilter?: string;
    clearCache?: boolean;
};

/**
 * Updates the filters on the call log store and triggers a request for new data
 * @param callLogStore - The call log store to update
 * @param filters - The filters to apply to the call    log
 */
export const useUpdateTeamCallLogFilters = (
    callLogStore: CompanyCallLogStore,
    filters?: TeamCallLogFiltersConfig,
): void => {
    const appHistory = useAppHistory();
    const companyCode = useActiveCompanyCode();

    useEffect(() => {
        const PAGE_SIZE = 100;
        const previousLocationPathname = appHistory[appHistory.length - 1];
        const navigatingFromCallDetailsPageYn =
            previousLocationPathname &&
            previousLocationPathname.includes('rm_') &&
            appHistory.length > 1;

        let promise: CancellablePromise<void>;

        // If returning from a call detail page, display cached data while refreshing (including search filter) for updated results
        if (navigatingFromCallDetailsPageYn) {
            promise = callLogStore.refresh();
            return;
        }

        promise = callLogStore.fetchFirstPage(
            {
                companyCodeOrSid: companyCode,
                pageSize: PAGE_SIZE,
                filter: filters.searchStringFilter,
                groupNames: filters.requestGroupNames,
                callSourceCodes: filters.requestSourceCodes,
            },
            { clearCache: !navigatingFromCallDetailsPageYn && filters.clearCache },
        );

        return () => {
            promise?.cancel();
        };
    }, [
        callLogStore,
        companyCode,
        filters.searchStringFilter,
        filters.requestGroupNames.toString(),
        filters.requestSourceCodes.toString(),
    ]);
};
