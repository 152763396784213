import { CallDetailsCustomerDetails } from './call_details_customer_details';
import { CallDetailsPhotosNotesModelLegacy } from './call_details_photos_notes';
import { PageWrapper } from '../page_wrapper';

export const LegacyCallDetailContent = ({ readOnly }: { readOnly?: boolean }): JSX.Element => {
    return (
        <PageWrapper data-testid="call-details">
            <CallDetailsCustomerDetails />
            <CallDetailsPhotosNotesModelLegacy readOnly={readOnly} />
        </PageWrapper>
    );
};
