/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleProtobufDuration,
    GoogleProtobufDurationFromJSON,
    GoogleProtobufDurationFromJSONTyped,
    GoogleProtobufDurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemInternalApiGroupReservationWaitMetrics
 */
export interface StreemInternalApiGroupReservationWaitMetrics {
    /**
     * 
     * @type {GoogleProtobufDuration}
     * @memberof StreemInternalApiGroupReservationWaitMetrics
     */
    averageCallLength?: GoogleProtobufDuration;
    /**
     * 
     * @type {GoogleProtobufDuration}
     * @memberof StreemInternalApiGroupReservationWaitMetrics
     */
    estimatedWaitTime?: GoogleProtobufDuration;
    /**
     * 
     * @type {number}
     * @memberof StreemInternalApiGroupReservationWaitMetrics
     */
    queuePosition?: number;
}

export function StreemInternalApiGroupReservationWaitMetricsFromJSON(json: any): StreemInternalApiGroupReservationWaitMetrics {
    return StreemInternalApiGroupReservationWaitMetricsFromJSONTyped(json, false);
}

export function StreemInternalApiGroupReservationWaitMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemInternalApiGroupReservationWaitMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'averageCallLength': !exists(json, 'average_call_length') ? undefined : GoogleProtobufDurationFromJSON(json['average_call_length']),
        'estimatedWaitTime': !exists(json, 'estimated_wait_time') ? undefined : GoogleProtobufDurationFromJSON(json['estimated_wait_time']),
        'queuePosition': !exists(json, 'queue_position') ? undefined : json['queue_position'],
    };
}

export function StreemInternalApiGroupReservationWaitMetricsToJSON(value?: StreemInternalApiGroupReservationWaitMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'average_call_length': GoogleProtobufDurationToJSON(value.averageCallLength),
        'estimated_wait_time': GoogleProtobufDurationToJSON(value.estimatedWaitTime),
        'queue_position': value.queuePosition,
    };
}


