import { useObservable } from '@streem/sdk-react';
import { useDetailSession } from './detail_session_hooks';
import { useGlobalStore } from './use_global_context';
import { TranscriptEntry, useRawTranscription } from './use_raw_transcription';

export const useTranscription = (): TranscriptEntry[] => {
    const { companySettingsStore } = useGlobalStore();
    const detailSession = useDetailSession();
    const [transcriptions = []] = useObservable(detailSession.transcription.transcriptions);
    const transcriptionsEnabled = companySettingsStore.transcriptionsEnabled;

    const transcription =
        transcriptionsEnabled && transcriptions.length > 0 ? transcriptions[0] : undefined;

    const transcriptionEntries = useRawTranscription(transcription?.transcription?.url ?? null);
    return transcriptionEntries;
};
