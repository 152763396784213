import { MemberInformation } from '@streem/sdk-react';
import { Row, styled, Theme } from '@streem/ui-react';
import { useCallDetailHeaderInfo } from '../../../../hooks/use_call_detail_header_info';
import { DiagnosisComponent } from './diagnosis_component';

export const MemberInformationAndDiagnosis = (): JSX.Element => {
    const { callDetail } = useCallDetailHeaderInfo();

    if (!callDetail) {
        return null;
    }

    const { customer } = callDetail;

    return (
        <Wrapper>
            <MemberInfoAndDiagnosisWrapper paddingTop="1rem">
                <MemberInformation
                    customer={customer}
                    widthPercentage={100}
                    columnsPerRow={1}
                    dataIdPrefix="post-call-details"
                    memberInformationStyles={{ titleFontSize: '1.125rem' }}
                />
            </MemberInfoAndDiagnosisWrapper>
            {/* The diagnosis component already has a .75rem top margin */}
            <MemberInfoAndDiagnosisWrapper paddingTop=".25rem">
                <DiagnosisComponent />
            </MemberInfoAndDiagnosisWrapper>
        </Wrapper>
    );
};

const Wrapper = styled(Row)({
    columnGap: '2rem',
    width: '100%',
});

const MemberInfoAndDiagnosisWrapper = styled.div<{ paddingTop: string }>(({ paddingTop }) => ({
    border: `1px solid ${Theme.colors.grey10}`,
    borderRadius: '4px',
    padding: `${paddingTop} 1.5rem 1.1rem 1.5rem`, // the diagnosis component has bottom padding of .4rem
    width: '50%',
}));
