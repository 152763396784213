import { Column, styled } from '@streem/ui-react';

import { Items } from './items';
import { MemberInformationAndDiagnosis } from './member_information_and_diagnosis';
import { useCallDetailHeaderInfo } from '../../../../hooks/use_call_detail_header_info';
import { scrollIntoViewRefCallback } from '../../../../util/scroll_into_view_ref_callback';

export const CallDetailDetailTab = (): JSX.Element => {
    // Note: when this component is fully implement, scrollIntoViewRefCallback should be moved
    //       to the first child element, so that the app scrolls to the top on the initial render.
    const { callDetail, groupName } = useCallDetailHeaderInfo();
    return (
        <Wrapper ref={scrollIntoViewRefCallback}>
            <Items groupName={groupName} items={callDetail?.items ?? []} />
            <MemberInformationAndDiagnosis />
        </Wrapper>
    );
};

const Wrapper = styled(Column)({
    rowGap: '4rem',
});
