import { CallDetailsPhotosNotesModelLegacy } from '../legacy/call_details_photos_notes';
import { CallDetailForReservation } from './call_detail_for_reservation';
import { PageWrapper } from '../page_wrapper';

export const CallDetailNoTab = ({ readOnly }: { readOnly?: boolean }): JSX.Element => {
    return (
        <PageWrapper data-testid="call-details">
            <CallDetailForReservation />
            <CallDetailsPhotosNotesModelLegacy readOnly={readOnly} />
        </PageWrapper>
    );
};
