import { useEffect, ChangeEvent, useState } from 'react';
import { TabLink, TabbedComponentContainer, Box, PageLink } from '@streem/ui-react';
import PageHeader from '../../components/page_header/page_header';
import { MyCallLogPage } from './my_call_log_page';
import debounce from 'lodash.debounce';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import { createGetCompanyStore } from '../../hooks/detail_store_hooks';
import { observer } from 'mobx-react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { TeamCallLogPage } from './team_call_log_page';
import { RolesAuth } from '../../components/authorization/roles_auth';
import { MyFavoritesCallLogPage } from './my_favorites_call_log_page';
import { useGetEmbedEnabledSettings } from '../../hooks/use_get_embed_enabled_settings';
import { useGlobalStore } from '../../hooks/use_global_context';
import { PageColumn } from '../page_column';
import { useCallLogSavedFilter } from '../../hooks/use_call_log_saved_filter';

export const CallLogsPage = observer(() => {
    const { isEmbedView } = useGetEmbedEnabledSettings();
    const history = useHistory();
    const pathName = useLocation().pathname;
    const companyCode = useActiveCompanyCode();
    const savedSearchValue = useCallLogSavedFilter();
    const [searchString, setSearchString] = useState(savedSearchValue);
    const { companySettingsStore } = useGlobalStore();

    const debounceSearch = debounce(setSearchString, 1_000);

    const getCompanyStore = createGetCompanyStore();

    const tabLinks: TabLink[] = [
        {
            link: () => (
                <RolesAuth except="AGENT" key="streems-link">
                    <Box marginLeft={5}>
                        <PageLink
                            onClick={() => history.push(`./teamcalls`)}
                            active={pathName.includes('teamcalls') ?? false}
                            title="Team Calls"
                        />
                    </Box>
                </RolesAuth>
            ),
        },
        {
            link: () => (
                <RolesAuth except="SUPER_ADMIN" key="mystreems-link">
                    <Box marginLeft={5}>
                        <PageLink
                            onClick={() => history.push(`./mycalls`)}
                            active={pathName.includes('mycalls') || false}
                            title="My Calls"
                        />
                    </Box>
                </RolesAuth>
            ),
        },
        {
            link: () => {
                if (isEmbedView || !companySettingsStore.callFavoritingEnabled) {
                    return null;
                }
                return (
                    <RolesAuth except="SUPER_ADMIN" key="myfavorites-link">
                        <Box marginLeft={5}>
                            <PageLink
                                onClick={() => history.push(`./myfavorites`)}
                                active={pathName.includes('myfavorites') || false}
                                title="My Favorites"
                            />
                        </Box>
                    </RolesAuth>
                );
            },
        },
    ];

    useEffect(() => {
        const promise = getCompanyStore.fetch(companyCode);
        return () => promise.cancel();
    }, [getCompanyStore, companyCode]);

    const filterSearch = (e: ChangeEvent<HTMLInputElement>) => {
        e.persist();
        debounceSearch(e.target.value.trim());
    };

    return (
        <PageColumn>
            <Box pb={4} px={4}>
                <PageHeader
                    title="Streem Calls"
                    onSearch={filterSearch}
                    searchPlaceholder={'Search Streem Calls'}
                    defaultValue={searchString}
                />
            </Box>
            <TabbedComponentContainer tabLinks={tabLinks} overflowY="hidden">
                <Switch>
                    <Route
                        key={'streems'}
                        path={['/companies/:company/teamcalls', '/teamcalls']}
                        exact={true}
                    >
                        <TeamCallLogPage
                            searchString={searchString}
                            clearSearch={() => {
                                setSearchString('');
                            }}
                        />
                    </Route>
                    <RolesAuth except="SUPER_ADMIN">
                        {!isEmbedView && (
                            <Route path="/myfavorites" exact={true} key="mystreems">
                                <MyFavoritesCallLogPage searchString={searchString} />
                            </Route>
                        )}
                        <Route path="/mycalls" exact={true} key="mycalls">
                            <MyCallLogPage searchString={searchString} />
                        </Route>
                    </RolesAuth>
                </Switch>
            </TabbedComponentContainer>
        </PageColumn>
    );
});
