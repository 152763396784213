import { Bookmark, DetailSession } from '@streem/sdk-core';
import { Column, styled, Theme } from '@streem/ui-react';
import { streem } from 'streem-sdk-protobuf';
import { useBookmarks } from '../../../../hooks/use_bookmark';
import { isRecordingPending } from '../../../../util/artifacts';
import { MediaGalleryBookmark } from '../../../media_gallery/bookmark';
import { AddNewBookmark } from '../../../media_gallery/media_gallery';
import { Header } from './shared_styled_components';
import { sortBookmarks } from './utils';

type AddBookmark = (bookmark: streem.api.Artifact.IBookmark) => void;

export type BookmarksProps = {
    readOnly?: boolean;
};
export const Bookmarks = ({ readOnly }: BookmarksProps): JSX.Element => {
    const { detailSession, recordings, bookmarks, userId, firstRecording } = useBookmarks();

    // if hidden, do not render the bookmarks section (the Video Bookmarks header will not be shown)
    const hideBookmarksYn =
        recordings.length === 0 || (readOnly && bookmarks.length === 0) || !firstRecording;
    if (hideBookmarksYn) {
        return null;
    }

    const addBookmark = (bookmark: streem.api.Artifact.IBookmark): void => {
        detailSession.bookmarks.addBookmark(bookmark);
    };
    const sortedBookmarks = sortBookmarks(bookmarks);
    const someRecordingsPendingYn = isRecordingPending(recordings);

    return (
        <BookmarksView
            addBookmark={addBookmark}
            sortedBookmarks={sortedBookmarks}
            currentUserId={userId}
            detailSession={detailSession}
            someRecordingsPendingYn={someRecordingsPendingYn}
            readOnly={readOnly}
        />
    );
};

interface BookmarksViewProps {
    addBookmark: AddBookmark;
    sortedBookmarks: Bookmark[];
    currentUserId: string;
    detailSession: DetailSession;
    someRecordingsPendingYn?: boolean;
    readOnly?: boolean;
}
const BookmarksView = ({
    addBookmark,
    sortedBookmarks,
    currentUserId,
    detailSession,
    someRecordingsPendingYn,
    readOnly,
}: BookmarksViewProps): JSX.Element => {
    // check to see if Add New Bookmark should be displayed
    const canAddBookmarkYn = !someRecordingsPendingYn && !readOnly;
    const maybeAddNewBookmarkComponent: JSX.Element | undefined = canAddBookmarkYn && (
        <AddNewBookmark
            addBookmark={addBookmark}
            WrapperComponent={AddBookmarkWrapper}
            buttonTextStyles={{
                fontSize: Theme.text.size.medium,
                lineHeight: Theme.text.lineHeight.medium,
            }}
        />
    );

    return (
        <Wrapper data-testid="bookmarks">
            <Header>Video Bookmarks</Header>
            {maybeAddNewBookmarkComponent}
            <BookmarkGalleryView
                sortedBookmarks={sortedBookmarks}
                currentUserId={currentUserId}
                detailSession={detailSession}
                someRecordingsPendingYn={someRecordingsPendingYn}
            />
        </Wrapper>
    );
};

interface BookmarkGalleryViewProps {
    sortedBookmarks: Bookmark[];
    currentUserId: string;
    detailSession: DetailSession;
    someRecordingsPendingYn?: boolean;
}
const BookmarkGalleryView = ({
    sortedBookmarks,
    currentUserId,
    detailSession,
    someRecordingsPendingYn,
}: BookmarkGalleryViewProps): JSX.Element => {
    return (
        <BookmarkGalleryWrapper>
            {sortedBookmarks.map(bookmark => {
                const updateBookmark = (label: string): void => {
                    detailSession.bookmarks.updateBookmark(bookmark.id, {
                        ...bookmark.bookmark,
                        label,
                    });
                };
                const deleteBookmark = (): Promise<void> => {
                    return detailSession.bookmarks.deleteBookmark(bookmark.id);
                };
                return (
                    <MediaGalleryBookmark
                        key={bookmark.id}
                        bookmarkArtifact={bookmark}
                        enableToolTip={someRecordingsPendingYn}
                        disableTimestamp={someRecordingsPendingYn}
                        isBookmarkOwner={currentUserId === bookmark.ownerUserId}
                        updateBookmark={updateBookmark}
                        deleteBookmark={deleteBookmark}
                    />
                );
            })}
        </BookmarkGalleryWrapper>
    );
};

const BookmarkGalleryWrapper = styled(Column)({
    paddingLeft: '.75rem',
    paddingTop: '.5rem',
    rowGap: '.125rem', // 2px + 12px padding on bottom of each bookmark
});

const AddBookmarkWrapper = styled.div({
    // due to padding on the button, need a negative margin to align with the video tile
    marginLeft: '-1.5rem',
});
const Wrapper = styled.div({
    marginTop: '2.25rem',
});
