import { styled } from '@streem/ui-react';
import { TranscriptEntry } from '../../../../hooks/use_raw_transcription';
import { useTranscription } from '../../../../hooks/use_trancription';
import { CallTranscription } from '../../../media_gallery/call_transcription';
import { Header } from './shared_styled_components';

export const Transcription = (): JSX.Element => {
    const transcriptionEntries = useTranscription();

    return <TranscriptionView transcriptionEntries={transcriptionEntries} />;
};

interface TranscriptionViewProps {
    transcriptionEntries: TranscriptEntry[];
}
const TranscriptionView = ({ transcriptionEntries }: TranscriptionViewProps): JSX.Element => {
    if (!Array.isArray(transcriptionEntries) || transcriptionEntries.length === 0) {
        return null;
    }

    return (
        <TranscriptionWrapper>
            <Header>Transcriptions</Header>
            <CallTranscription rawTranscription={transcriptionEntries} />
        </TranscriptionWrapper>
    );
};

const TranscriptionWrapper = styled.div({
    marginTop: '2.5rem',
});
