import { Logger } from '@streem/logger';
import { Recording, Streemshot } from '@streem/sdk-core';
import { invariant } from '@streem/toolbox';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { StreemshotData } from '../hooks/use_fetch_streemshot_data';

export interface StreemshotImageData {
    [url: string]: StreemshotData;
}

const getSelectedStreemshots = (
    streemshots: Streemshot[],
    selectedStreemshotIds: Set<string>,
): Streemshot[] =>
    streemshots.filter(
        ss => selectedStreemshotIds.has(ss.id) || selectedStreemshotIds.has(ss?.streemshotId),
    );

const getSelectedRecordings = (
    recordings: Recording[],
    selectedRecordingIds: Set<string>,
): Recording[] => recordings.filter(r => selectedRecordingIds.has(r.id));

const objectUrlToBlob = (objectURL: string): Promise<Blob> => {
    return new Promise((res, rej) => {
        fetch(objectURL)
            .then(r => res(r.blob()))
            .catch(err => rej(err));
    });
};

const addBlobToFile = (
    imageFolder: JSZip,
    promiseResults: PromiseSettledResult<Blob>[],
    log: Logger,
    setErrorMessage: (message: string) => void,
): boolean => {
    let validZip = false;
    promiseResults.forEach((result, index) => {
        if (result.status === 'rejected') {
            setErrorMessage('Some images could not be downloaded');
            log.error('Error attempting to download streemshot: ', result.reason);
        }
        if (result.status === 'fulfilled' && result.value) {
            const streemshotBlob = result.value;
            const streemshotFormat = streemshotBlob.type;
            let streemshotExtension = '';
            let fileName = '';
            switch (streemshotFormat) {
                case 'image/png':
                    streemshotExtension = 'png';
                    fileName = `streemshot-${index + 1}.${streemshotExtension}`;
                    break;
                case 'image/jpeg':
                    streemshotExtension = 'jpg';
                    fileName = `streemshot-${index + 1}.${streemshotExtension}`;
                    break;
                case 'video/mp4':
                    streemshotExtension = 'mp4';
                    fileName = `record-${index + 1}.${streemshotExtension}`;
                    break;
                default:
                    // https://caniuse.com/mdn-api_blob_type
                    // 8/2/21: blob.type unsupported on Safari iOS
                    // Most streemshots going forward will be in jpg, so use as default
                    log.warn('Unknown streemshot format:', streemshotFormat, 'Defaulting to jpg');
                    streemshotExtension = 'jpg';
                    break;
            }
            imageFolder?.file(fileName, streemshotBlob);
            validZip = true;
        }
    });
    return validZip;
};

export const saveAssetsToDisk = async (
    streemshots: Streemshot[],
    recordings: Recording[],
    selectedStreemshotIds: Set<string>,
    selectedRecordingIds: Set<string>,
    setErrorMessage: (message: string) => void,
    log: Logger,
    streemshotsImageDataRef?: React.MutableRefObject<StreemshotImageData>,
): Promise<void> => {
    const zip = new JSZip();
    const imageFolder = zip.folder('images');
    let validStreemshotZip = true;
    let validRecordingZip = true;

    const selectedStreemshots = getSelectedStreemshots(streemshots, selectedStreemshotIds);

    if (selectedStreemshots.length > 0) {
        validStreemshotZip = await getStreemshotBlobPromises();
    }

    const selectedRecordings = getSelectedRecordings(recordings, selectedRecordingIds);
    if (selectedRecordings.length > 0) {
        validRecordingZip = await getRecordingBlobPromises();
    }

    if (validStreemshotZip && validRecordingZip) {
        try {
            const content = await imageFolder?.generateAsync({ type: 'blob' });
            invariant(!!content, 'Error generating content for assets for download');
            saveAs(content, 'assets.zip');
        } catch (e) {
            setErrorMessage('Error generating .zip file.');
            log.error('Error generating streemshot .zip file: ', e);
        }
    } else {
        setErrorMessage('Error validating .zip file.');
        log.error('Error validating assets.zip file: ');
    }

    async function getStreemshotBlobPromises() {
        const streemshotBlobPromises = selectedStreemshots.reduce(
            (blobArray: Promise<Blob>[], streemshot) => {
                const downloadURL = streemshot.downloadUrl;
                const currentStreemshotImageData = streemshotsImageDataRef?.current;
                if (
                    downloadURL &&
                    currentStreemshotImageData &&
                    Object.prototype.hasOwnProperty.call(currentStreemshotImageData, downloadURL)
                ) {
                    const streemshotBlob = objectUrlToBlob(
                        currentStreemshotImageData[downloadURL].dataURL,
                    );
                    blobArray.push(streemshotBlob);
                }
                return blobArray;
            },
            [],
        );
        const streemshotResults = await Promise.allSettled(streemshotBlobPromises);
        return addBlobToFile(imageFolder, streemshotResults, log, setErrorMessage);
    }

    async function getRecordingBlobPromises() {
        const recordingBlobPromise = selectedRecordings.reduce(
            (blobArray: Promise<Blob>[], recording) => {
                const downloadURL = recording.recording.downloadUrl;
                if (downloadURL) {
                    const recordingBlob = objectUrlToBlob(downloadURL);
                    blobArray.push(recordingBlob);
                }
                return blobArray;
            },
            [],
        );
        const recordingResults = await Promise.allSettled(recordingBlobPromise);
        return addBlobToFile(imageFolder, recordingResults, log, setErrorMessage);
    }
};

/**
 * Only returns 'true' when all selected streemshots have successfully loaded
 */
export const awaitSelectionCache = (
    streemshots: Streemshot[],
    selectedStreemshotIds: Set<string>,
    streemshotsImageDataRef?: React.MutableRefObject<StreemshotImageData>,
): Promise<boolean> => {
    // TODO: use this logic somewhere for UI update (need designs)
    // let progress = ''

    const selectedStreemshots = getSelectedStreemshots(streemshots, selectedStreemshotIds);
    return new Promise(res => {
        const checkCacheMatches = setInterval(() => {
            const cacheMatches = selectedStreemshots.filter(ss => {
                invariant(
                    streemshotsImageDataRef?.current,
                    'streemshotsImageDataRef must be populated for selecting streemshots',
                );
                const downloadURL = ss.downloadUrl;
                return downloadURL && downloadURL in streemshotsImageDataRef.current;
            });
            /* TODO: use this logic somewhere for UI update (need designs)
    const progressUpdate = (`${cacheMatches.length} of ${selectedStreemshots.length} images cached.`);
    if (progressUpdate !== progress) {
        progress = `${cacheMatches.length} of ${selectedStreemshots.length} images cached.`
        console.log(progress)
    }
*/
            if (cacheMatches.length === selectedStreemshots.length) {
                res(true);
                clearInterval(checkCacheMatches);
            }
        }, 250);
    });
};
