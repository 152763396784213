import { TabbedComponentContainer, PageLink, TabLink, Box } from '@streem/ui-react';
import { useState } from 'react';
import { CallDetailPhotosTab } from './call_detail_photos_tab';
import { CallDetailSummaryTab } from './call_detail_summary_tab';
import { CallDetailDetailTab } from './call_details/call_detail_detail_tab';

enum CallDetailTab {
    SUMMARY = 'summary',
    PHOTOS = 'photos',
    CALL_DETAILS = 'call_details',
}

export const CallDetailTabs = ({ readOnly }: { readOnly?: boolean }): JSX.Element => {
    const [selectedTab, setSelectedTab] = useState<CallDetailTab>(CallDetailTab.SUMMARY);
    const onClickFn = (tabToClick: CallDetailTab) => () => {
        if (selectedTab !== tabToClick) {
            setSelectedTab(tabToClick);
        }
    };
    const isActive = (tabToCheck: CallDetailTab) => selectedTab === tabToCheck;

    const tabLinks: TabLink[] = [
        {
            link: () => (
                <Box marginRight={5} key="summary">
                    <PageLink
                        onClick={onClickFn(CallDetailTab.SUMMARY)}
                        title="Summary"
                        active={isActive(CallDetailTab.SUMMARY)}
                    />
                </Box>
            ),
        },
        {
            link: () => (
                <Box marginRight={5} key="photos">
                    <PageLink
                        onClick={onClickFn(CallDetailTab.PHOTOS)}
                        title="Photos"
                        active={isActive(CallDetailTab.PHOTOS)}
                    />
                </Box>
            ),
        },
        {
            link: () => (
                <Box marginRight={5} key="call-details">
                    <PageLink
                        onClick={onClickFn(CallDetailTab.CALL_DETAILS)}
                        title="Call Details"
                        active={isActive(CallDetailTab.CALL_DETAILS)}
                    />
                </Box>
            ),
        },
    ];

    return <TabComponent selectedTab={selectedTab} tabLinks={tabLinks} readOnly={readOnly} />;
};

interface TabProps {
    selectedTab: CallDetailTab;
    tabLinks: TabLink[];
    readOnly?: boolean;
}
const TabComponent = ({ selectedTab, tabLinks, readOnly }: TabProps): JSX.Element => {
    let currentTabComponent: JSX.Element | undefined;
    switch (selectedTab) {
        case CallDetailTab.SUMMARY:
            currentTabComponent = <CallDetailSummaryTab readOnly={readOnly} />;
            break;
        case CallDetailTab.PHOTOS:
            currentTabComponent = <CallDetailPhotosTab />;
            break;
        case CallDetailTab.CALL_DETAILS:
            currentTabComponent = <CallDetailDetailTab />;
            break;
        default:
            throw new Error(`Unknown tab: ${selectedTab}`);
            break;
    }

    return (
        <TabbedComponentContainer
            tabLinks={tabLinks}
            data-testid="call-details-tabs"
            tabsPaddingBottom="2.5rem"
            containerMarginX={0}
        >
            {currentTabComponent}
        </TabbedComponentContainer>
    );
};
