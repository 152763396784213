import { Streemshot, StreemshotDetection } from '@streem/sdk-core';
import {
    dedupeDetections,
    ModelSerialList,
    useMediaBreakpoint,
    useObservable,
} from '@streem/sdk-react';
import { EditableNote, MarkDownNotes, PoweredByStreem, styled, Theme } from '@streem/ui-react';
import { FC, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { streem } from 'streem-sdk-protobuf';
import { useDetailSession } from '../../../hooks/detail_session_hooks';
import { useAnonymousShareChecker } from '../../../hooks/use_anonymous_share_checker';
import { useGlobalStore } from '../../../hooks/use_global_context';
import appLogger from '../../../util/logging/app_logger';
import { MediaGallery } from '../../media_gallery/media_gallery';
import { CallDetailsLocation } from '../call_details_location';

interface CallDetailsPhotosNotesLegacyProps {
    readOnly?: boolean;
}

export const CallDetailsPhotosNotesModelLegacy = ({
    readOnly,
}: CallDetailsPhotosNotesLegacyProps): JSX.Element => {
    const log = appLogger.extend('edit call notes');
    const detailSession = useDetailSession();
    const { authStore, companySettingsStore, configStore } = useGlobalStore();
    const isAnonymousSharePage = useAnonymousShareChecker(authStore);
    const { isMobile } = useMediaBreakpoint();
    const history = useHistory();

    const [roomNote] = useObservable(detailSession.note.roomNote);
    const [showMarkdown, setShowMarkdown] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [modelSerial = []] = useObservable(
        detailSession.detection.getDetections({
            types: [
                streem.api.Detection.Type.DETECTION_TYPE_MODEL,
                streem.api.Detection.Type.DETECTION_TYPE_SERIAL,
            ],
        }),
    );
    const [streemshots = []] = useObservable(detailSession.streemshots.responses);

    // must fetch the public (pre-auth) settings from configStore for this view
    // since it's displayed as an anonymous share link
    const gpsEnabled = configStore.gpsEnabled;
    const videoPreviewEnabled = configStore.videoTrackPreviewEnabled;

    const handleEdit = useCallback(() => {
        setShowMarkdown(false);
    }, []);

    const onSave = async (noteText: string) => {
        setShowMarkdown(true);
        try {
            setErrorMessage(undefined);
            await detailSession.note.saveRoomNote(noteText);
        } catch (error) {
            setErrorMessage('Error attempting to edit call notes.');
            log.error('Error attempting to edit call notes: ', error);
        }
    };
    const handleModelSerialClick = (
        detection: StreemshotDetection,
        streemshotToDisplay?: Streemshot,
    ) => {
        const url = `${location.pathname}/artifact/${
            streemshotToDisplay?.id || detection.streemshotSid
        }`;
        history.push(url);
    };

    const dedupedModelSerial = useMemo(() => dedupeDetections(modelSerial), [modelSerial]);

    return (
        <>
            <HorizontalBreak />
            {isAnonymousSharePage && isMobile && (
                <div style={{ marginBottom: '40px' }}>
                    <MediaGallery videoPreviewEnabled={videoPreviewEnabled} readOnly={readOnly} />
                </div>
            )}
            <TwoColumns isAnonymousSharePage={isAnonymousSharePage}>
                <CallNotes
                    showMarkdown={showMarkdown}
                    text={roomNote?.text}
                    handleEdit={handleEdit}
                    isAnonymousSharePage={isAnonymousSharePage}
                    roomNoteCharLimit={companySettingsStore.roomNoteCharLimit}
                    onSave={onSave}
                    readOnly={readOnly}
                    errorMessage={errorMessage}
                />
                <div>
                    {gpsEnabled && <CallDetailsLocation />}
                    <ModelSerialList
                        modelSerialDetections={dedupedModelSerial}
                        streemshotBaseUrl={`${location.pathname}/artifact`}
                        streemshots={streemshots}
                        hasFootbar={false}
                        onClickHandler={handleModelSerialClick}
                    />
                </div>
            </TwoColumns>
            {(!isAnonymousSharePage || !isMobile) && (
                <MediaGallery videoPreviewEnabled={videoPreviewEnabled} readOnly={readOnly} />
            )}
            {isAnonymousSharePage && (
                <StreemFooterContainer>
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a href="https://www.streem.com/try-remote-video" target="_blank">
                        <PoweredByStreem />
                    </a>
                </StreemFooterContainer>
            )}
        </>
    );
};

export interface CallNotesProps {
    showMarkdown: boolean;
    text?: string;
    handleEdit: () => void;
    isAnonymousSharePage: boolean;
    roomNoteCharLimit: number;
    onSave: (noteText: string) => Promise<void>;
    readOnly?: boolean;
    errorMessage?: string;
    showTitle?: boolean;
}
export const CallNotes: FC<CallNotesProps> = ({
    showMarkdown,
    text,
    handleEdit,
    isAnonymousSharePage,
    roomNoteCharLimit,
    onSave,
    readOnly,
    errorMessage,
    showTitle = true,
}) => {
    return showMarkdown && text !== undefined ? (
        <MarkDownNotes
            text={text}
            onEdit={handleEdit}
            showTitle={showTitle}
            readOnly={isAnonymousSharePage}
        />
    ) : (
        <EditableNote
            description="Call Notes"
            placeholderText="Add call notes"
            characterLimit={roomNoteCharLimit}
            noteText={text}
            disableEditing={readOnly}
            onSave={onSave}
            errorMessage={errorMessage}
            hasFootBar={false}
            showChrome={false}
            isEditing={!showMarkdown}
            showTitle={showTitle}
        />
    );
};

const HorizontalBreak = styled.hr(({ theme }) => ({
    height: '1px',
    backgroundColor: theme.colors.grey10,
    border: 'none',
    margin: '24px 0',
}));

const TwoColumns = styled.div<{ isAnonymousSharePage: boolean }>(
    ({ isAnonymousSharePage, theme }) => ({
        display: 'grid',
        gridTemplateColumns: 'minMax(0, 1fr) minMax(0, 1fr)',
        gridTemplateRows: 'auto',
        columnGap: '32px',
        marginBottom: '24px',
        paddingBottom: '24px',
        borderBottom: isAnonymousSharePage ? 'none' : `solid 1px ${theme.colors.grey10}`,
        '@media (max-width: 500px)': {
            gridTemplateColumns: '1fr',
            gridRowGap: '32px',
        },
    }),
);

const StreemFooterContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Theme.colors.grey05,
    width: '100%',
    marginTop: '40px',
});
