import { CallDetailItems, MemberInformation, RemSize } from '@streem/sdk-react';
import { Column, Flex, styled, Theme } from '@streem/ui-react';
import { invariant } from 'mobx-utils';
import { FC } from 'react';
import { streem } from 'streem-sdk-protobuf';
import { useCallDetailHeaderInfo } from '../../../hooks/use_call_detail_header_info';
import appLogger from '../../../util/logging/app_logger';

// this component is responsible for gathering the data required to render
//      the member information and call details
export const MemberAndItems: FC = () => {
    const { callDetail, groupName } = useCallDetailHeaderInfo();

    if (!callDetail) {
        return null;
    }

    const trade = groupName;
    const { customer, items } = callDetail;

    invariant(customer != null, 'Customer must be defined');

    if (trade == null) {
        appLogger.warn('Call details: unexpected condition: trade is null');
    }

    return <MemberInfoAndItems customer={customer} items={items} trade={trade ?? ''} />;
};

interface MemberInfoAndItemsProps {
    customer: streem.api.CallDetail.ICustomer;
    items: streem.api.CallDetail.IItem[];
    trade: string;
}
// this component is responsible for rendering  the member information and call details
//     for a call
const MemberInfoAndItems = ({ customer, items, trade }: MemberInfoAndItemsProps) => {
    // when there are less than 2 items, we want to display the member information
    //    and call details to share a single row
    const singleRow = items.length < 2;
    const titleFontSize = Theme.text.size.notQuiteAllTheWayMediumLarge as RemSize;
    const itemFontSize = Theme.text.size.medium as RemSize;
    const keyValueStyles = { fontSize: itemFontSize };

    const Wrapper = singleRow
        ? MemberInfoAndItemsWrapperSingleRow
        : MemberInfoAndItemsWrapperMultipleRows;

    return (
        <Wrapper>
            <CallDetailItems
                items={items}
                maxItemsPerRow={4}
                trade={trade ?? ''}
                widthPercentage={singleRow ? 50 : 100}
                dataIdPrefix="post-call-details"
                keyValueStyles={keyValueStyles}
                keyValueTitleStyles={{ fontSize: titleFontSize }}
                callDetailItemStyles={{
                    titleFontSize: Theme.text.size.notQuiteAllTheWayMediumLarge as RemSize,
                }}
            />
            <MemberInformation
                customer={customer}
                widthPercentage={singleRow ? 50 : 100}
                columnsPerRow={singleRow ? 1 : 3}
                dataIdPrefix="post-call-details"
                keyValueStyles={keyValueStyles}
                memberInformationStyles={{ titleFontSize, headerBottomMargin: '0.75rem' }}
            />
        </Wrapper>
    );
};

const MemberInfoAndItemsWrapperSingleRow = styled(Flex)({
    // want member information first for single item, so reverse the order
    flexDirection: 'row-reverse',
    columnGap: '2rem',
    paddingTop: '1rem',
    alignItems: 'stretch',
});

const MemberInfoAndItemsWrapperMultipleRows = styled(Column)({
    rowGap: '.75rem',
    paddingTop: '1rem',
});
