import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Container } from './page_header.styles';
import {
    Header,
    IconButton,
    RoundedSearch,
    IconNames,
    AppText,
    Avatar,
    Flex,
    Column,
    Box,
    useTheme,
    Tooltip,
    styled,
    Theme,
    AppIcon,
} from '@streem/ui-react';
import { recordElementClicked, recordTooltipViewed } from '@streem/analytics';
import { CallFiltersModal } from '../call_details/call_filters_modal';
import { ListGroupsProvider } from '../../hooks/list_store_hooks';
import { useGlobalStore } from '../../hooks/use_global_context';
import { usePageHeader } from '../../hooks/use_page_header';

interface Props {
    title?: string;
    subtitle?: string;
    avatarSrc?: string;
    avatarDisplayName?: string;
    searchPlaceholder?: string;
    onSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
    iconName?: IconNames;
    iconContext?: string;
    handleClick?: () => void;
    defaultValue?: string;
}

const PageHeader: React.FC<Props> = ({
    title,
    subtitle,
    onSearch,
    iconName,
    iconContext,
    handleClick,
    searchPlaceholder = 'Search',
    avatarSrc = '',
    avatarDisplayName = '',
    defaultValue,
}) => {
    const {
        companyCode,
        companyStore,
        groupsStore,
        isMyCallsPage,
        isTeamCallsPage,
        callLogStore,
        callLogFilterEnabled,
        isAHSEnabled,
        path,
    } = usePageHeader();
    const theme = useTheme();
    const [showTooltip, setShowTooltip] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const tooltipId = 'blue-plus-team-icon-tooltip';

    function shouldDisplayFilterButton() {
        if (isMyCallsPage && isAHSEnabled) {
            return true;
        }
        if (isTeamCallsPage && callLogFilterEnabled) {
            return true;
        }
        return false;
    }

    useEffect(() => {
        const flows = [companyStore.fetch(companyCode), groupsStore.fetch(companyCode)];
        return () => flows.forEach(p => p.cancel());
    }, [companyStore, groupsStore, companyCode]);

    const handleMouseEnter = useCallback(() => {
        setShowTooltip(true);
        recordTooltipViewed(tooltipId);
    }, []);

    const handleMouseLeave = useCallback(() => {
        setShowTooltip(false);
    }, []);

    const handleOpen = useCallback(() => {
        recordElementClicked(`${path}-log-filter-opened`);
        setIsOpen(true);
    }, [path]);

    const onClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <Container hasIcon={Boolean(iconName)} hasFilter={shouldDisplayFilterButton()}>
            <Column>
                <Flex>
                    {avatarSrc?.length || avatarDisplayName?.length ? (
                        <Box paddingRight={`${theme.space[4]}px`}>
                            <Avatar
                                src={avatarSrc}
                                displayName={avatarDisplayName}
                                size={'medium'}
                            />
                        </Box>
                    ) : null}
                    <Column>
                        <Box paddingBottom={`${theme.space[2]}px`}>
                            <Header style={{ wordBreak: 'break-word' }} size="xxlarge">
                                {title}
                            </Header>
                        </Box>
                        {subtitle && (
                            <AppText as="h4" size="mediumLarge" headingFontFamily>
                                {subtitle}
                            </AppText>
                        )}
                    </Column>
                </Flex>
            </Column>
            {onSearch && (
                <Column>
                    <Flex justifyContent="end">
                        <RoundedSearch
                            data-testid="admin-header-search"
                            name={searchPlaceholder}
                            label={searchPlaceholder}
                            placeholder={searchPlaceholder}
                            onChange={onSearch}
                            defaultValue={defaultValue}
                        />
                    </Flex>
                </Column>
            )}
            {shouldDisplayFilterButton() && <FilterIcon handleOpen={handleOpen} />}

            {iconName && (
                <Column alignItems={!onSearch ? 'flex-end' : 'center'} justifyContent={'center'}>
                    <Box position={onSearch ? undefined : 'absolute'}>
                        <Tooltip
                            message={iconContext}
                            showTooltip={showTooltip}
                            placement="bottom"
                            fallbackPlacements={['bottom']}
                        >
                            <IconButton
                                data-testid="admin-header-button"
                                iconName={iconName}
                                label={iconName}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                                background={'azure'}
                                fill={'white'}
                                onClick={handleClick!}
                            />
                        </Tooltip>
                    </Box>
                </Column>
            )}
            <ListGroupsProvider value={groupsStore}>
                <CallFiltersModal
                    isOpen={isOpen}
                    onClose={onClose}
                    pathName={path}
                    callLogStore={callLogStore}
                />
            </ListGroupsProvider>
        </Container>
    );
};

const FilterButton = styled.button`
    margin-left: 6px;
    text-align: left;
    color: ${Theme.colors.blue50};
    background: transparent;
    border: none;
    :hover {
        color: ${Theme.colors.blue70};
        cursor: pointer;
    }
`;

interface FilterIconProps {
    handleOpen: () => void;
    storedFiltersCount?: number;
}
export const FilterIcon: FC<FilterIconProps> = observer(({ handleOpen }) => {
    const { uiStore } = useGlobalStore();
    const appliedGroupFiltersCount = uiStore.appliedGroupFilters.length;
    const appliedSourceFiltersCount = uiStore.appliedSourceFiltersSelected.length;

    // 'mycalls' page wont have group filters
    const totalFiltersApplied = window.location.pathname.includes('teamcalls')
        ? appliedGroupFiltersCount + appliedSourceFiltersCount
        : appliedSourceFiltersCount;

    return (
        <Column alignItems="flex-start" style={{ minWidth: '10%', lineHeight: 2.4 }}>
            <FilterButton onClick={handleOpen} data-testid="call-log-filter-button">
                <AppIcon color={Theme.colors.blue50} name="FilterIcon" />
                <AppText
                    style={{
                        marginLeft: '6px',
                        color: 'inherit',
                        lineHeight: 2,
                        fontSize: '1.2rem',
                    }}
                    data-testid="call-log-filter-button-label"
                >
                    {`Filters (${totalFiltersApplied})`}
                </AppText>
            </FilterButton>
        </Column>
    );
});

export default PageHeader;
