import { Bookmark, DetailSession, Recording } from '@streem/sdk-core';
import { useObservable } from '@streem/sdk-react';
import { WallItemSubtype } from '@streem/sdk-types';
import { WallItem } from 'streem-sdk-protobuf';
import { useDetailSession } from './detail_session_hooks';
import { useGlobalStore } from './use_global_context';

interface BookmarkData {
    detailSession: DetailSession;
    recordings: Recording[];
    bookmarks: Bookmark[];
    userId: string;
    firstRecording: WallItemSubtype<WallItem, 'artifact'>;
}
export const useBookmarks = (): BookmarkData => {
    const detailSession = useDetailSession();
    const [recordings = []] = useObservable(detailSession.recording.recordings);
    const [bookmarks = []] = useObservable(detailSession.bookmarks.bookmarks);
    const { authStore } = useGlobalStore();

    // callStartTime is a recording artifact from the wall.  I am not sure why it is
    //     called callStartTime, but I nominate it for inclusion in the list of most
    //     confusing variable names.
    const [callStartTime] = useObservable(detailSession.bookmarks.callStartTime);

    return {
        detailSession,
        recordings,
        bookmarks,
        userId: authStore.userId,
        firstRecording: callStartTime,
    };
};
