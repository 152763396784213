import { useEffect, useState, useCallback } from 'react';
import appLogger from '../util/logging/app_logger';

const log = appLogger.extend('useRawTranscription');

export interface TranscriptEntry {
    timestamp: string;
    text: string;
}

// See https://developer.mozilla.org/en-US/docs/Web/API/WebVTT_API for format examples of WEBVTT
export function parseVtt(vttData: string): TranscriptEntry[] {
    const lines = vttData.trim().split('\n');
    const transcriptEntries = [];

    for (let i = 0; i < lines.length; i++) {
        if (/\d+:\d+:\d+\.\d+ --> \d+:\d+:\d+\.\d+/.test(lines[i])) {
            // This line contains the timestamp, so extract it
            const timestamp = lines[i];
            const text = [];
            i++; // Move to the next line

            // Collect all lines until an empty line or end of array
            while (i < lines.length && lines[i].trim() !== '') {
                text.push(lines[i]);
                i++;
            }

            // Join the text lines into a single string
            const entry = {
                timestamp,
                text: text.join(' '),
            };

            transcriptEntries.push(entry);
        }
    }

    return transcriptEntries;
}

export const convertTimestampToFormattedTime = (timestamp: string) => {
    const [start] = timestamp.split(' --> ');
    const [hh, mm, ss] = start.split(':').map(parseFloat);
    const formattedStart = hh * 3600 + mm * 60 + Math.round(ss);

    const hour = Math.floor(formattedStart / 3600);
    const minute = Math.floor((formattedStart % 3600) / 60);
    const seconds = (formattedStart % 60).toFixed(0).padStart(2, '0');
    return `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}:${seconds}`;
};

export const useRawTranscription = (transcriptionUrl: string): TranscriptEntry[] => {
    const [rawTranscription, setRawTranscription] = useState<TranscriptEntry[]>([]);

    const fetchTranscription = useCallback(async (url: string) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                log.error('Error fetching raw transcript');
                return;
            }

            const data = await response.text();
            const transcriptEntries = parseVtt(data);
            setRawTranscription(transcriptEntries);
        } catch (error) {
            log.error(`Error fetching raw transcript: ${error}`);
        }
    }, []);

    useEffect(() => {
        if (transcriptionUrl) {
            fetchTranscription(transcriptionUrl);
        }
    }, [transcriptionUrl, fetchTranscription]);

    return rawTranscription;
};
