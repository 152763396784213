import { Diagnosis, useObservable } from '@streem/sdk-react';
import { RemSize } from '@streem/sdk-react/src/types/style.types';
import { Row, styled, Theme } from '@streem/ui-react';
import { FC } from 'react';
import { useDetailSession } from '../../../hooks/detail_session_hooks';
import { convertProtobufToDiagnosisProps } from '../../../util/convert_room_outcome_protobuf';

// this component is responsible for gathering the diagnosis data for a room
export const CallDetailDiagnosis: FC = () => {
    const detailSession = useDetailSession();
    const [roomOutcomeReport] = useObservable(detailSession.personalizations.roomOutcomeReport);

    const diagnosisProps = roomOutcomeReport?.roomOutcomeReport
        ? convertProtobufToDiagnosisProps(roomOutcomeReport.roomOutcomeReport)
        : undefined;
    if (!diagnosisProps) {
        return null;
    }

    const { diagnoses } = diagnosisProps;
    return (
        <CallDetailDiagnosisComponent
            diagnoses={diagnoses}
            disposition={diagnosisProps.disposition}
            outOfScopeDiscussionYn={diagnosisProps.outOfScopeDiscussionYn}
            outOfScopeDiscussionReasons={diagnosisProps.outOfScopeDiscussionReasons}
            refundRequest={diagnosisProps.shouldRefundYn}
            refundReason={diagnosisProps.refundReason}
        />
    );
};

interface CallDetailDiagnosisProps {
    diagnoses: string[];
    disposition: string;
    outOfScopeDiscussionYn?: boolean;
    outOfScopeDiscussionReasons?: string[];
    refundRequest?: boolean;
    refundReason?: string;
}
// this component is responsible for rendering the diagnosis data for a room
const CallDetailDiagnosisComponent = ({
    diagnoses,
    disposition,
    outOfScopeDiscussionYn,
    outOfScopeDiscussionReasons,
    refundRequest,
    refundReason,
}: CallDetailDiagnosisProps) => {
    return (
        <DiagnosisWrapper>
            <Diagnosis
                diagnoses={diagnoses}
                disposition={disposition}
                outOfScopeDiscussion={outOfScopeDiscussionYn}
                outOfScopeDiscussionReasons={outOfScopeDiscussionReasons}
                refundRequest={refundRequest}
                refundReason={refundReason}
                dataIdPrefix="post-call-details"
                diagnosisStyles={{
                    titleFontSize: Theme.text.size.notQuiteAllTheWayMediumLarge as RemSize,
                }}
                keyValueStyles={{ fontSize: Theme.text.size.medium as RemSize }}
            />
        </DiagnosisWrapper>
    );
};

const DiagnosisWrapper = styled(Row)({});
