/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StreemApiCreateGroupRequest,
    StreemApiCreateGroupRequestFromJSON,
    StreemApiCreateGroupRequestToJSON,
    StreemApiCreateGroupReservationRequest,
    StreemApiCreateGroupReservationRequestFromJSON,
    StreemApiCreateGroupReservationRequestToJSON,
    StreemApiCreateGroupReservationResponse,
    StreemApiCreateGroupReservationResponseFromJSON,
    StreemApiCreateGroupReservationResponseToJSON,
    StreemApiCreateGroupResponse,
    StreemApiCreateGroupResponseFromJSON,
    StreemApiCreateGroupResponseToJSON,
    StreemApiGetGroupReservationResponse,
    StreemApiGetGroupReservationResponseFromJSON,
    StreemApiGetGroupReservationResponseToJSON,
    StreemApiGetGroupReservationWaitTimeResponse,
    StreemApiGetGroupReservationWaitTimeResponseFromJSON,
    StreemApiGetGroupReservationWaitTimeResponseToJSON,
    StreemApiGetGroupResponse,
    StreemApiGetGroupResponseFromJSON,
    StreemApiGetGroupResponseToJSON,
    StreemApiListGroupAvailabilityRequest,
    StreemApiListGroupAvailabilityRequestFromJSON,
    StreemApiListGroupAvailabilityRequestToJSON,
    StreemApiListGroupUsersResponse,
    StreemApiListGroupUsersResponseFromJSON,
    StreemApiListGroupUsersResponseToJSON,
    StreemApiListGroupsResponse,
    StreemApiListGroupsResponseFromJSON,
    StreemApiListGroupsResponseToJSON,
    StreemApiListUserGroupsResponse,
    StreemApiListUserGroupsResponseFromJSON,
    StreemApiListUserGroupsResponseToJSON,
    StreemInternalApiSaveGroupAverageCallLengthRequest,
    StreemInternalApiSaveGroupAverageCallLengthRequestFromJSON,
    StreemInternalApiSaveGroupAverageCallLengthRequestToJSON,
    StreemInternalApiSaveGroupAverageCallLengthResponse,
    StreemInternalApiSaveGroupAverageCallLengthResponseFromJSON,
    StreemInternalApiSaveGroupAverageCallLengthResponseToJSON,
} from '../models';

export interface AddGroupUserRequest {
    companyCodeOrSid: string;
    groupName: string;
    userId: string;
}

export interface CancelGroupReservationRequest {
    companyCodeOrSid: string;
    groupName: string;
    reservationSid: string;
}

export interface ConfirmMatchedGroupReservationRequest {
    companyCodeOrSid: string;
    groupName: string;
    reservationSid: string;
}

export interface CreateGroupRequest {
    companyCodeOrSid: string;
    streemApiCreateGroupRequest?: StreemApiCreateGroupRequest;
}

export interface CreateGroupReservationRequest {
    companyCodeOrSid: string;
    groupName: string;
    streemApiCreateGroupReservationRequest?: StreemApiCreateGroupReservationRequest;
}

export interface DeleteGroupRequest {
    companyCodeOrSid: string;
    groupName: string;
}

export interface DeleteGroupUserRequest {
    companyCodeOrSid: string;
    groupName: string;
    userId: string;
}

export interface GetGroupRequest {
    companyCodeOrSid: string;
    groupName: string;
}

export interface GetGroupReservationRequest {
    companyCodeOrSid: string;
    groupName: string;
    reservationSid: string;
}

export interface GetGroupReservationWaitTimeRequest {
    companyCodeOrSid: string;
    groupName: string;
    priority: number;
}

export interface ListGroupAvailabilityRequest {
    companyCodeOrSid: string;
    groupName: string;
    status?: ListGroupAvailabilityStatus;
    pageSize?: number;
    pageToken?: string;
}

export interface ListGroupUsersRequest {
    companyCodeOrSid: string;
    groupName: string;
    pageSize?: number;
    pageToken?: string;
}

export interface ListGroupsRequest {
    companyCodeOrSid: string;
}

export interface ListUserGroupsRequest {
    companyCodeOrSid: string;
    userId: string;
    pageSize?: number;
    pageToken?: string;
}

export interface SaveGroupReservationWaitMetricRequest {
    companyCodeOrSid: string;
    streemInternalApiSaveGroupAverageCallLengthRequest?: StreemInternalApiSaveGroupAverageCallLengthRequest;
}

/**
 * 
 */
export class GroupsApi extends runtime.BaseAPI {

    /**
     * Add a user to a group for a specific company.
     * Add user to group for company
     */
    async addGroupUserRaw(requestParameters: AddGroupUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling addGroupUser.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling addGroupUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling addGroupUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/users/{user_id}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Add a user to a group for a specific company.
     * Add user to group for company
     */
    async addGroupUser(companyCodeOrSid: string, groupName: string, userId: string): Promise<void> {
        await this.addGroupUserRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, userId: userId });
    }

    /**
     * Cancel a group reservation for a specific company and group
     * Cancel group reservation
     */
    async cancelGroupReservationRaw(requestParameters: CancelGroupReservationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling cancelGroupReservation.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling cancelGroupReservation.');
        }

        if (requestParameters.reservationSid === null || requestParameters.reservationSid === undefined) {
            throw new runtime.RequiredError('reservationSid','Required parameter requestParameters.reservationSid was null or undefined when calling cancelGroupReservation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/reservations/{reservation_sid}/cancel`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))).replace(`{${"reservation_sid"}}`, encodeURIComponent(String(requestParameters.reservationSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Cancel a group reservation for a specific company and group
     * Cancel group reservation
     */
    async cancelGroupReservation(companyCodeOrSid: string, groupName: string, reservationSid: string): Promise<void> {
        await this.cancelGroupReservationRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, reservationSid: reservationSid });
    }

    /**
     * Confirm a group reservation for a specific company and group
     * Confirm group reservation
     */
    async confirmMatchedGroupReservationRaw(requestParameters: ConfirmMatchedGroupReservationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling confirmMatchedGroupReservation.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling confirmMatchedGroupReservation.');
        }

        if (requestParameters.reservationSid === null || requestParameters.reservationSid === undefined) {
            throw new runtime.RequiredError('reservationSid','Required parameter requestParameters.reservationSid was null or undefined when calling confirmMatchedGroupReservation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/reservations/{reservation_sid}/confirm`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))).replace(`{${"reservation_sid"}}`, encodeURIComponent(String(requestParameters.reservationSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Confirm a group reservation for a specific company and group
     * Confirm group reservation
     */
    async confirmMatchedGroupReservation(companyCodeOrSid: string, groupName: string, reservationSid: string): Promise<void> {
        await this.confirmMatchedGroupReservationRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, reservationSid: reservationSid });
    }

    /**
     * Create a group for a specific company
     * Create group for company
     */
    async createGroupRaw(requestParameters: CreateGroupRequest): Promise<runtime.ApiResponse<StreemApiCreateGroupResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling createGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateGroupRequestToJSON(requestParameters.streemApiCreateGroupRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateGroupResponseFromJSON(jsonValue));
    }

    /**
     * Create a group for a specific company
     * Create group for company
     */
    async createGroup(companyCodeOrSid: string, streemApiCreateGroupRequest?: StreemApiCreateGroupRequest): Promise<StreemApiCreateGroupResponse> {
        const response = await this.createGroupRaw({ companyCodeOrSid: companyCodeOrSid, streemApiCreateGroupRequest: streemApiCreateGroupRequest });
        return await response.value();
    }

    /**
     * Create a group reservation for a specific company and group
     * Create group reservation for company
     */
    async createGroupReservationRaw(requestParameters: CreateGroupReservationRequest): Promise<runtime.ApiResponse<StreemApiCreateGroupReservationResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling createGroupReservation.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling createGroupReservation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/reservations`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemApiCreateGroupReservationRequestToJSON(requestParameters.streemApiCreateGroupReservationRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiCreateGroupReservationResponseFromJSON(jsonValue));
    }

    /**
     * Create a group reservation for a specific company and group
     * Create group reservation for company
     */
    async createGroupReservation(companyCodeOrSid: string, groupName: string, streemApiCreateGroupReservationRequest?: StreemApiCreateGroupReservationRequest): Promise<StreemApiCreateGroupReservationResponse> {
        const response = await this.createGroupReservationRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, streemApiCreateGroupReservationRequest: streemApiCreateGroupReservationRequest });
        return await response.value();
    }

    /**
     * Delete a group from a company
     * Delete group from company
     */
    async deleteGroupRaw(requestParameters: DeleteGroupRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling deleteGroup.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling deleteGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a group from a company
     * Delete group from company
     */
    async deleteGroup(companyCodeOrSid: string, groupName: string): Promise<void> {
        await this.deleteGroupRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName });
    }

    /**
     * Remove a user from a group for a specific company.
     * Remove user from group for company
     */
    async deleteGroupUserRaw(requestParameters: DeleteGroupUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling deleteGroupUser.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling deleteGroupUser.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteGroupUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/users/{user_id}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Remove a user from a group for a specific company.
     * Remove user from group for company
     */
    async deleteGroupUser(companyCodeOrSid: string, groupName: string, userId: string): Promise<void> {
        await this.deleteGroupUserRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, userId: userId });
    }

    /**
     * Get a group for a company by its name
     * Get group for company
     */
    async getGroupRaw(requestParameters: GetGroupRequest): Promise<runtime.ApiResponse<StreemApiGetGroupResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getGroup.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling getGroup.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetGroupResponseFromJSON(jsonValue));
    }

    /**
     * Get a group for a company by its name
     * Get group for company
     */
    async getGroup(companyCodeOrSid: string, groupName: string): Promise<StreemApiGetGroupResponse> {
        const response = await this.getGroupRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName });
        return await response.value();
    }

    /**
     * Get a specified group reservation
     * Get Group Reservation
     */
    async getGroupReservationRaw(requestParameters: GetGroupReservationRequest): Promise<runtime.ApiResponse<StreemApiGetGroupReservationResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getGroupReservation.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling getGroupReservation.');
        }

        if (requestParameters.reservationSid === null || requestParameters.reservationSid === undefined) {
            throw new runtime.RequiredError('reservationSid','Required parameter requestParameters.reservationSid was null or undefined when calling getGroupReservation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/reservations/{reservation_sid}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))).replace(`{${"reservation_sid"}}`, encodeURIComponent(String(requestParameters.reservationSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetGroupReservationResponseFromJSON(jsonValue));
    }

    /**
     * Get a specified group reservation
     * Get Group Reservation
     */
    async getGroupReservation(companyCodeOrSid: string, groupName: string, reservationSid: string): Promise<StreemApiGetGroupReservationResponse> {
        const response = await this.getGroupReservationRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, reservationSid: reservationSid });
        return await response.value();
    }

    /**
     * Get group reservation wait time for a specific group
     * Get group reservation wait time
     */
    async getGroupReservationWaitTimeRaw(requestParameters: GetGroupReservationWaitTimeRequest): Promise<runtime.ApiResponse<StreemApiGetGroupReservationWaitTimeResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling getGroupReservationWaitTime.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling getGroupReservationWaitTime.');
        }

        if (requestParameters.priority === null || requestParameters.priority === undefined) {
            throw new runtime.RequiredError('priority','Required parameter requestParameters.priority was null or undefined when calling getGroupReservationWaitTime.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/reservations/{priority}/wait_time`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))).replace(`{${"priority"}}`, encodeURIComponent(String(requestParameters.priority))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiGetGroupReservationWaitTimeResponseFromJSON(jsonValue));
    }

    /**
     * Get group reservation wait time for a specific group
     * Get group reservation wait time
     */
    async getGroupReservationWaitTime(companyCodeOrSid: string, groupName: string, priority: number): Promise<StreemApiGetGroupReservationWaitTimeResponse> {
        const response = await this.getGroupReservationWaitTimeRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, priority: priority });
        return await response.value();
    }

    /**
     * List the users in the group that have a certain availability status
     * List Group Availability
     */
    async listGroupAvailabilityRaw(requestParameters: ListGroupAvailabilityRequest): Promise<runtime.ApiResponse<StreemApiListGroupAvailabilityRequest>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling listGroupAvailability.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling listGroupAvailability.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/availability`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListGroupAvailabilityRequestFromJSON(jsonValue));
    }

    /**
     * List the users in the group that have a certain availability status
     * List Group Availability
     */
    async listGroupAvailability(companyCodeOrSid: string, groupName: string, status?: ListGroupAvailabilityStatus, pageSize?: number, pageToken?: string): Promise<StreemApiListGroupAvailabilityRequest> {
        const response = await this.listGroupAvailabilityRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, status: status, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * List the users that belong to a group.
     * List users in group
     */
    async listGroupUsersRaw(requestParameters: ListGroupUsersRequest): Promise<runtime.ApiResponse<StreemApiListGroupUsersResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling listGroupUsers.');
        }

        if (requestParameters.groupName === null || requestParameters.groupName === undefined) {
            throw new runtime.RequiredError('groupName','Required parameter requestParameters.groupName was null or undefined when calling listGroupUsers.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/{group_name}/users`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"group_name"}}`, encodeURIComponent(String(requestParameters.groupName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListGroupUsersResponseFromJSON(jsonValue));
    }

    /**
     * List the users that belong to a group.
     * List users in group
     */
    async listGroupUsers(companyCodeOrSid: string, groupName: string, pageSize?: number, pageToken?: string): Promise<StreemApiListGroupUsersResponse> {
        const response = await this.listGroupUsersRaw({ companyCodeOrSid: companyCodeOrSid, groupName: groupName, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     * List the groups associated with the company
     * List groups for company
     */
    async listGroupsRaw(requestParameters: ListGroupsRequest): Promise<runtime.ApiResponse<StreemApiListGroupsResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling listGroups.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListGroupsResponseFromJSON(jsonValue));
    }

    /**
     * List the groups associated with the company
     * List groups for company
     */
    async listGroups(companyCodeOrSid: string): Promise<StreemApiListGroupsResponse> {
        const response = await this.listGroupsRaw({ companyCodeOrSid: companyCodeOrSid });
        return await response.value();
    }

    /**
     * List the groups that a user belongs to.
     * List user\'s groups
     */
    async listUserGroupsRaw(requestParameters: ListUserGroupsRequest): Promise<runtime.ApiResponse<StreemApiListUserGroupsResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling listUserGroups.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling listUserGroups.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.pageToken !== undefined) {
            queryParameters['page_token'] = requestParameters.pageToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/users/{user_id}`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemApiListUserGroupsResponseFromJSON(jsonValue));
    }

    /**
     * List the groups that a user belongs to.
     * List user\'s groups
     */
    async listUserGroups(companyCodeOrSid: string, userId: string, pageSize?: number, pageToken?: string): Promise<StreemApiListUserGroupsResponse> {
        const response = await this.listUserGroupsRaw({ companyCodeOrSid: companyCodeOrSid, userId: userId, pageSize: pageSize, pageToken: pageToken });
        return await response.value();
    }

    /**
     */
    async saveGroupReservationWaitMetricRaw(requestParameters: SaveGroupReservationWaitMetricRequest): Promise<runtime.ApiResponse<StreemInternalApiSaveGroupAverageCallLengthResponse>> {
        if (requestParameters.companyCodeOrSid === null || requestParameters.companyCodeOrSid === undefined) {
            throw new runtime.RequiredError('companyCodeOrSid','Required parameter requestParameters.companyCodeOrSid was null or undefined when calling saveGroupReservationWaitMetric.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer-auth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/companies/{company_code_or_sid}/groups/reservation-metrics`.replace(`{${"company_code_or_sid"}}`, encodeURIComponent(String(requestParameters.companyCodeOrSid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StreemInternalApiSaveGroupAverageCallLengthRequestToJSON(requestParameters.streemInternalApiSaveGroupAverageCallLengthRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StreemInternalApiSaveGroupAverageCallLengthResponseFromJSON(jsonValue));
    }

    /**
     */
    async saveGroupReservationWaitMetric(companyCodeOrSid: string, streemInternalApiSaveGroupAverageCallLengthRequest?: StreemInternalApiSaveGroupAverageCallLengthRequest): Promise<StreemInternalApiSaveGroupAverageCallLengthResponse> {
        const response = await this.saveGroupReservationWaitMetricRaw({ companyCodeOrSid: companyCodeOrSid, streemInternalApiSaveGroupAverageCallLengthRequest: streemInternalApiSaveGroupAverageCallLengthRequest });
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListGroupAvailabilityStatus {
    AVAILABLE = 'AVAILABILITY_STATUS_AVAILABLE',
    NOTAVAILABLE = 'AVAILABILITY_STATUS_NOT_AVAILABLE',
    RESERVED = 'AVAILABILITY_STATUS_RESERVED',
    INCALL = 'AVAILABILITY_STATUS_IN_CALL',
    CONFIRMING = 'AVAILABILITY_STATUS_CONFIRMING',
    OFFLINE = 'AVAILABILITY_STATUS_OFFLINE',
    INVALID = 'AVAILABILITY_STATUS_INVALID'
}
