/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiPriorityWaitTime,
    StreemApiPriorityWaitTimeFromJSON,
    StreemApiPriorityWaitTimeFromJSONTyped,
    StreemApiPriorityWaitTimeToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiEventsGroupWaitTimeUpdated
 */
export interface StreemApiEventsGroupWaitTimeUpdated {
    /**
     * 
     * @type {string}
     * @memberof StreemApiEventsGroupWaitTimeUpdated
     */
    companySid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiEventsGroupWaitTimeUpdated
     */
    groupSid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemApiEventsGroupWaitTimeUpdated
     */
    groupName?: string;
    /**
     * 
     * @type {Array<StreemApiPriorityWaitTime>}
     * @memberof StreemApiEventsGroupWaitTimeUpdated
     */
    priorityWaitTime?: Array<StreemApiPriorityWaitTime>;
}

export function StreemApiEventsGroupWaitTimeUpdatedFromJSON(json: any): StreemApiEventsGroupWaitTimeUpdated {
    return StreemApiEventsGroupWaitTimeUpdatedFromJSONTyped(json, false);
}

export function StreemApiEventsGroupWaitTimeUpdatedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiEventsGroupWaitTimeUpdated {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {

        'companySid': !exists(json, 'company_sid') ? undefined : json['company_sid'],
        'groupSid': !exists(json, 'group_sid') ? undefined : json['group_sid'],
        'groupName': !exists(json, 'group_name') ? undefined : json['group_name'],
        'priorityWaitTime': !exists(json, 'priority_wait_time') ? undefined : ((json['priority_wait_time'] as Array<any>).map(StreemApiPriorityWaitTimeFromJSON)),
    };
}

export function StreemApiEventsGroupWaitTimeUpdatedToJSON(value?: StreemApiEventsGroupWaitTimeUpdated | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {

        'company_sid': value.companySid,
        'group_sid': value.groupSid,
        'group_name': value.groupName,
        'priority_wait_time': value.priorityWaitTime === undefined ? undefined : ((value.priorityWaitTime as Array<any>).map(StreemApiPriorityWaitTimeToJSON)),
    };
}
