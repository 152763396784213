import { observable, action, runInAction } from 'mobx';
import { ToasterItem } from '@streem/ui-react';
import appLogger from '../util/logging/app_logger';
import { Logger } from '@streem/logger';
import { recordElementClicked } from '@streem/analytics';

export class UIStore {
    private log: Logger;
    public toastTimeout: { [key: string]: number } = {};

    constructor() {
        this.toggleVerticalNavBar(this.getMainNavState());
        this.log = appLogger.extend(`UIStore`);
    }

    @observable
    public allowNotificationsModalShown = Boolean(this.getAllowNotificationsModalShown());

    @observable
    public companySurveyUrl?: URL;

    @observable
    public streemSurveyUrl?: URL;

    @observable
    public multiSurveyPromptVisible = false;

    @observable
    public mainNavExpanded = Boolean(this.getMainNavState());

    @observable
    public userJustRefreshedPage = Boolean(this.getUserJustHitBrowserRefresh());

    @observable
    public companyName = 'Company Settings';

    @observable.shallow
    public toasts: ToasterItem[] = [];

    @observable
    public bannerNotificationContent?: () => React.ReactNode;

    @observable
    public shouldClearCache = false;

    @observable
    public appliedGroupFilters: string[] = (() => {
        const selectedFilters = this.getGroupFiltersSelected();
        return selectedFilters !== null && selectedFilters.length > 0
            ? [...selectedFilters.split(',')]
            : [];
    })();

    @observable
    public appliedSourceFiltersSelected: string[] = (() => {
        const selectedSourceFilters = this.getSourceFiltersSelected();
        return selectedSourceFilters !== null && selectedSourceFilters.length > 0
            ? [...selectedSourceFilters.split(',')]
            : [];
    })();

    @action
    public setCompanySurveyUrl(surveyUrl: string, roomId: string) {
        if (!roomId || !surveyUrl) {
            return;
        }

        try {
            this.companySurveyUrl = new URL(`?roomid=${roomId}`, surveyUrl);
        } catch (error) {
            this.log.error(
                `Failed to set company survey url ${surveyUrl} for room ${roomId} `,
                error,
            );
        }
    }

    @action
    public setStreemSurveyUrl(surveyUrl: string, roomId: string) {
        if (!roomId || !surveyUrl) {
            return;
        }

        try {
            this.streemSurveyUrl = new URL(`?roomid=${roomId}`, surveyUrl);
        } catch (error) {
            this.log.error(
                `Failed to set streem survey url ${surveyUrl} for room ${roomId} `,
                error,
            );
        }
    }

    @action
    public clearCompanySurveyUrl() {
        this.companySurveyUrl = undefined;
    }

    @action
    public clearStreemSurveyUrl() {
        this.streemSurveyUrl = undefined;
    }

    @action
    public clearSurveys() {
        this.companySurveyUrl = undefined;
        this.streemSurveyUrl = undefined;
        this.setMultiSurveyPrompt(false);
    }

    @action
    public toggleVerticalNavBar(mainNavState?: boolean) {
        if (mainNavState === undefined) {
            this.mainNavExpanded = !this.mainNavExpanded;
        } else {
            this.mainNavExpanded = mainNavState;
        }

        this.setVerticalNavBarSetting();
    }

    @action
    public updateCompanyName(companyName: string) {
        this.companyName = companyName ? companyName : 'Company Settings';
    }

    // keep track of when user refreshes the page
    // temporary workaround since we currently run through the entire oath redirect flow on each page refresh
    // and therefore don't have access to window.performance on the reload
    @action
    public getUserJustHitBrowserRefresh(): boolean {
        const lastPageRefresh = Number(sessionStorage.getItem('LAST_PAGE_REFRESH'));
        if (lastPageRefresh === 0) {
            // user never refreshed the page
            return false;
        } else if (new Date().getTime() - lastPageRefresh < 5000) {
            // user refreshed the page in the past n seconds
            // where n is some number that feels safe enough to call a refresh
            // rather than a user navigating quickly back and forth between workspaces
            return true;
        }
        return false;
    }

    @action
    public getMainNavState() {
        const settings = localStorage.getItem('mainNavExpanded');
        if (!settings) return true;
        try {
            return JSON.parse(settings);
        } catch {
            return true;
        }
    }

    @action
    private getAllowNotificationsModalShown() {
        return localStorage.getItem('ev.hide.allow.notifications') === 'true';
    }

    @action
    public setVerticalNavBarSetting() {
        localStorage.setItem('mainNavExpanded', JSON.stringify(this.mainNavExpanded));
    }

    @action
    public setUserJustHitBrowserRefresh() {
        sessionStorage.setItem('LAST_PAGE_REFRESH', new Date().getTime().toString());
    }

    @action.bound
    public setAllowNotificationsModalShown() {
        localStorage.setItem('ev.hide.allow.notifications', 'true');
        this.allowNotificationsModalShown = Boolean(true);
    }

    @action
    public getGroupFiltersSelected() {
        return localStorage.getItem('GroupNameFiltersSelected');
    }

    @action
    public getSourceFiltersSelected() {
        return localStorage.getItem('SourceFiltersSelected');
    }

    @action
    public addOrRemoveSelectedGroupFilter(filter: string, path: string): void {
        this.shouldClearCache = true;
        const index = this.appliedGroupFilters.indexOf(filter);
        if (index === -1) {
            this.appliedGroupFilters.push(filter);
            recordElementClicked(`${path}-log-filter-group-${filter}-checked`);
        } else {
            this.appliedGroupFilters.splice(index, 1);
            recordElementClicked(`${path}-log-filter-group-${filter}-unchecked`);
        }
        localStorage.setItem('GroupNameFiltersSelected', this.appliedGroupFilters.toString());
    }

    @action
    public addOrRemoveSourceFilter(filter: string, path: string): void {
        this.shouldClearCache = true;
        const index = this.appliedSourceFiltersSelected.indexOf(filter);
        if (index === -1) {
            this.appliedSourceFiltersSelected.push(filter);
            recordElementClicked(`${path}-log-filter-source-${filter}-checked`);
        } else {
            this.appliedSourceFiltersSelected.splice(index, 1);
            recordElementClicked(`${path}-log-filter-source-${filter}-unchecked`);
        }
        localStorage.setItem('SourceFiltersSelected', this.appliedSourceFiltersSelected.toString());
    }

    @action
    public clearCallLogFilters(path: string): void {
        recordElementClicked(`${path}-log-filter-reset-clicked`);
        this.appliedGroupFilters.splice(0, this.appliedGroupFilters.length);
        this.appliedSourceFiltersSelected.splice(0, this.appliedSourceFiltersSelected.length);
        localStorage.removeItem('SourceFiltersSelected');
        localStorage.removeItem('GroupNameFiltersSelected');
    }

    @action
    public resetCacheState() {
        this.shouldClearCache = false;
    }

    @action
    public addToast(toast: ToasterItem, isEmbedView = false) {
        if (isEmbedView) return;
        if (!this.toasts.find(({ id }) => toast.id === id)) {
            this.toasts.push(toast);
        } else {
            // if the toast already exists, clear the timeout and then reset it
            this.pauseRemoveToast(toast.id);
        }
        this.removeToastViaTimeout(toast.id);
    }

    @action
    public addToastWithManualClose(toast: ToasterItem, isEmbedView = false) {
        if (isEmbedView) return;
        this.toasts.push({ ...toast, manualClose: true });
    }

    @action
    public removeToast(idToRemove: string) {
        const newToasts = this.toasts.filter(toast => toast.id !== idToRemove);
        this.toasts = newToasts;
    }

    @action
    public removeToastViaTimeout(idToRemove: string, timeout = 5000) {
        const remove = window.setTimeout(() => {
            runInAction(() => {
                const newToasts = this.toasts.filter(toast => toast.id !== idToRemove);
                this.toasts = newToasts;
            });
            this.toastTimeout[idToRemove] = null;
        }, timeout);
        // if we haven't already set this toast to the timeout (aka it's new) do so
        if (!this.toastTimeout?.[idToRemove]) this.toastTimeout[idToRemove] = remove;
        // otherwise just call the timeout function again (when we've paused it and are resuming)
        else remove;
    }

    @action
    public pauseRemoveToast(id: string) {
        clearTimeout(this.toastTimeout[id]);
        this.toastTimeout[id] = null;
    }

    @action
    public removeAllToast() {
        this.toasts = [];
    }

    @action
    public addBannerNotification(content: () => React.ReactNode) {
        this.bannerNotificationContent = content;
    }

    @action
    public clearBannerNotification() {
        this.bannerNotificationContent = null;
    }

    @action
    public setMultiSurveyPrompt(isVisible: boolean) {
        this.multiSurveyPromptVisible = isVisible;
    }
}
