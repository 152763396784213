import { streem } from 'streem-sdk-protobuf';
import { useCallSourceData } from './use_call_source_data';

export const useWillOfferOptions = (
    configOptions?: streem.api.CompanyRoomOutcomeConfig.ISection,
): boolean => {
    const { callSource } = useCallSourceData();
    if (!configOptions) return false;

    const callSourceCode = callSource?.code?.toLocaleLowerCase();
    const options = configOptions?.options;

    // if callSourceCodes is empty, do not filter the options by callSourceCode
    const hasCallSourceCodes = configOptions?.callSourceCodes?.length > 0;

    const optionHasCallSourceCode = () =>
        options.some(o => o.entry.callSourceCodes?.includes(callSourceCode));

    return !hasCallSourceCodes || optionHasCallSourceCode();
};
