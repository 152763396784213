import { styled, Theme } from '@streem/ui-react';

export const Header = styled.h5({
    fontSize: Theme.text.size.notQuiteAllTheWayMediumLarge,
    lineHeight: Theme.text.lineHeight.notQuiteAllTheWayMediumLarge,
    fontWeight: 500,
    fontFamily: Theme.text.headingFontFamily,
    marginBottom: '1.5rem',
});

export const SummarySectionWrapper = styled.div({
    border: `1px solid ${Theme.colors.grey10}`,
    padding: '1.5rem',
});
