import { observer } from 'mobx-react';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    Header,
    Modal,
    Row,
    CheckboxGridContainer,
    Subheader,
} from '@streem/ui-react';
import { useListGroupsStore } from '../../hooks/list_store_hooks';
import { nonDisplayableGroupNames } from '../../util/constants';
import { useGlobalStore } from '../../hooks/use_global_context';
import { CheckboxLabel, CheckboxWrap } from '../../forms/terms_and_conditions_form';
import { recordElementClicked } from '@streem/analytics';
import { useGetCompanyStore } from '../../hooks/detail_store_hooks';
import { APITypes } from '@streem/api';
import type { CallLogStore } from '../../types/call_logs.types';

interface CallFiltersModalProps {
    isOpen: boolean;
    onClose: () => void;
    pathName: string;
    callLogStore: CallLogStore;
}

export const CallFiltersModal = observer(
    ({ isOpen, onClose, pathName, callLogStore }: CallFiltersModalProps): JSX.Element => {
        const handleXButtonClose = () => {
            recordElementClicked(`${pathName}-log-filter-x-clicked`);
            onClose();
        };

        return (
            <Modal
                isOpen={isOpen}
                hideCloseButton={false}
                data-testid="call-filters-modal"
                onClose={handleXButtonClose}
            >
                <CallLogFilterHeader />
                <CallFiltersGroupSection pathName={pathName} />
                <CallFiltersSourceSection pathName={pathName} />
                <ButtonSection
                    onClose={onClose}
                    pathName={pathName}
                    loadingResults={callLogStore.loading}
                    totalSizeOfResults={callLogStore.totalSizeOfResults}
                />
            </Modal>
        );
    },
);

const CallLogFilterHeader = () => {
    return (
        <>
            <Flex mb={24} justifyContent="flex-start" alignItems="center">
                <Header data-testid="call-filters-header" size="xxlarge">
                    Filter Streem Calls
                </Header>
            </Flex>
        </>
    );
};

const CallFiltersGroupSection = observer(({ pathName }: { pathName: string }) => {
    // we don't support group filters on mycalls page
    if (pathName == 'mycalls') {
        return null;
    }

    const groupsStore = useListGroupsStore();
    const { uiStore } = useGlobalStore();

    const appliedGroupFilters = uiStore.appliedGroupFilters;
    const filteredGroups = groupsStore.results.filter(group => {
        return !nonDisplayableGroupNames.includes(group.name.toUpperCase());
    });

    const itemsPerRow = 3;

    const isGroupFilterApplied = (group: APITypes.StreemApiGroup) => {
        return appliedGroupFilters?.some(
            filter => filter.toLocaleLowerCase() === group.name.toLocaleLowerCase(),
        );
    };

    const handleFilterRequest = (groupName: string) => {
        uiStore.addOrRemoveSelectedGroupFilter(groupName, pathName);
    };

    return (
        <>
            <Row>
                <Subheader data-testid="call-filters-trade-header" size="large" semibold>
                    Trade Groups
                </Subheader>
            </Row>
            <Box mb={4}>
                <CheckboxGridContainer itemsPerRow={itemsPerRow}>
                    {filteredGroups.map(group => (
                        <CheckboxWrap key={group.name}>
                            <Checkbox
                                data-testid={`call-filters-checkbox-${group.name}`}
                                key={group.name}
                                checked={isGroupFilterApplied(group)}
                                label={group.name}
                                onChange={() => handleFilterRequest(group.name)}
                            />
                            <CheckboxLabel
                                data-testid={`call-filters-checkbox-label-${group.name}`}
                            >
                                {group.name}
                            </CheckboxLabel>
                        </CheckboxWrap>
                    ))}
                </CheckboxGridContainer>
            </Box>
        </>
    );
});

const CallFiltersSourceSection = observer(({ pathName }: { pathName: string }) => {
    const { uiStore, companySettingsStore } = useGlobalStore();
    const companyStore = useGetCompanyStore();

    const appliedSourceFilters = uiStore.appliedSourceFiltersSelected;
    const companyCallSources = companyStore?.result?.callSources;
    const isAHSEnabled = companySettingsStore.ahsVirtualExpertEnabled;

    const handleCallSourceSearch = (code: string) => {
        uiStore.addOrRemoveSourceFilter(code, pathName);
    };

    const isSourceFilterApplied = (source: APITypes.StreemApiCallSource) => {
        return appliedSourceFilters?.some(filter => filter === source.code);
    };

    const itemsPerRow = 3;

    if (!isAHSEnabled) {
        return null;
    }

    return (
        <>
            <Row>
                <Subheader data-testid="call-filters-source-header" size="large" semibold>
                    Call Source
                </Subheader>
            </Row>
            <Box mb={4}>
                <CheckboxGridContainer itemsPerRow={itemsPerRow}>
                    {companyCallSources?.map(source => {
                        return (
                            <CheckboxWrap key={source.sid}>
                                <Checkbox
                                    checked={isSourceFilterApplied(source)}
                                    label={source.code}
                                    data-testid={`call-filters-checkbox-${source.name}`}
                                    onChange={() => {
                                        handleCallSourceSearch(source.code);
                                    }}
                                />
                                <CheckboxLabel
                                    data-testid={`call-filters-checkbox-label-${source.name}`}
                                >
                                    {source.name}
                                </CheckboxLabel>
                            </CheckboxWrap>
                        );
                    })}
                </CheckboxGridContainer>
            </Box>
        </>
    );
});

const ButtonSection = observer(
    ({
        onClose,
        pathName,
        loadingResults,
        totalSizeOfResults,
    }: {
        onClose: () => void;
        pathName: string;
        loadingResults: boolean;
        totalSizeOfResults: number;
    }) => {
        const { uiStore } = useGlobalStore();

        const handleResetFilters = () => {
            uiStore.clearCallLogFilters(pathName);
        };

        const handleShowResultsClose = () => {
            recordElementClicked(`${pathName}-log-filter-show-results-clicked`);
            onClose();
        };

        return (
            <Flex mt="2rem" justifyContent="space-between">
                <Button
                    variant="secondary"
                    data-testid="call-filters-button-reset"
                    onClick={handleResetFilters}
                >
                    Reset
                </Button>
                <Button
                    ml={4}
                    mobile
                    onClick={handleShowResultsClose}
                    loading={loadingResults}
                    data-testid="call-filters-button-results"
                >
                    {`Show ${totalSizeOfResults} results`}
                </Button>
            </Flex>
        );
    },
);
