import { Diagnosis, RemSize, useObservable } from '@streem/sdk-react';
import { Theme } from '@streem/ui-react';
import { useDetailSession } from '../../../../hooks/detail_session_hooks';
import { convertProtobufToDiagnosisProps } from '../../../../util/convert_room_outcome_protobuf';

export const DiagnosisComponent = (): JSX.Element => {
    const detailSession = useDetailSession();
    const [roomOutcomeReport] = useObservable(detailSession.personalizations.roomOutcomeReport);

    const diagnosisProps = roomOutcomeReport?.roomOutcomeReport
        ? convertProtobufToDiagnosisProps(roomOutcomeReport.roomOutcomeReport)
        : undefined;
    if (!diagnosisProps) {
        return null;
    }

    const { diagnoses } = diagnosisProps;
    const {
        disposition,
        outOfScopeDiscussionYn,
        outOfScopeDiscussionReasons,
        shouldRefundYn,
        refundReason,
    } = diagnosisProps;

    return (
        <Diagnosis
            diagnoses={diagnoses}
            disposition={disposition}
            outOfScopeDiscussion={outOfScopeDiscussionYn}
            outOfScopeDiscussionReasons={outOfScopeDiscussionReasons}
            refundRequest={shouldRefundYn}
            refundReason={refundReason}
            singleColumn
            dataIdPrefix="post-call-details"
            diagnosisStyles={{
                titleFontSize: Theme.text.size.notQuiteAllTheWayMediumLarge as RemSize,
            }}
            keyValueStyles={{ fontSize: Theme.text.size.medium as RemSize }}
        />
    );
};
