import { RecordingId, StreemshotId } from '@streem/domain-id';
import { Logger } from '@streem/logger';
import { DetailSession, Recording, Streemshot } from '@streem/sdk-core';
import { useState } from 'react';
import { useGetEmbedEnabledSettings } from '../../hooks/use_get_embed_enabled_settings';
import { useGlobalStore } from '../../hooks/use_global_context';
import { AppText, Button, ButtonRow, Card, IconButton, styled } from '@streem/ui-react';
import { DeleteConfirmation } from './delete_confirmation';
import {
    awaitSelectionCache,
    saveAssetsToDisk,
    StreemshotImageData,
} from '../../util/media_gallery_selected_utils';

export interface MediaGallerySelectedProps {
    detailSession: DetailSession;
    streemshots?: Streemshot[];
    selectedStreemshotIds?: Set<string>;
    recordings?: Recording[];
    selectedRecordingIds?: Set<string>;
    streemshotsImageDataRef?: React.MutableRefObject<StreemshotImageData>;
    selectionSize: number;
    isMobile: boolean;
    selectionVisible: boolean;
    readOnly?: boolean;
    onUnselect: () => void;
    onSelectAll: () => void;
    clearSelection: () => void;
    errorMessage?: string;
    setErrorMessage: (message: string) => void;
    logger: Logger;
}
export const MediaGallerySelected = ({
    detailSession,
    streemshots = [],
    selectedStreemshotIds = new Set(),
    recordings = [],
    selectedRecordingIds = new Set(),
    streemshotsImageDataRef,
    selectionSize,
    isMobile,
    selectionVisible,
    readOnly,
    onUnselect,
    onSelectAll,
    clearSelection,
    errorMessage,
    setErrorMessage,
    logger,
}: MediaGallerySelectedProps): JSX.Element => {
    const [deleteInProgress, setDeleteInProgress] = useState(false);
    const [downloadInProgress, setDownloadInProgress] = useState(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
    const { isEmbedView } = useGetEmbedEnabledSettings();
    const { uiStore } = useGlobalStore();

    const handleDelete = async () => {
        setDeleteInProgress(true);
        setErrorMessage('');

        const deletionTasks: Promise<void>[] = [];
        for (const sid of selectedStreemshotIds) {
            const task = detailSession.streemshots.deleteStreemshot(new StreemshotId(sid));
            deletionTasks.push(task);
        }

        for (const sid of selectedRecordingIds) {
            const task = detailSession.recording.deleteRecording(new RecordingId(sid));
            deletionTasks.push(task);
        }

        try {
            await Promise.allSettled(deletionTasks);
            clearSelection();
        } catch (e) {
            setErrorMessage('Error attempting to delete streemshots');
            logger.error('Error attempting to delete a streemshot', e);
        }

        setDeleteInProgress(false);
        setIsDeleteConfirmationOpen(false);
    };

    const handleDownload = async () => {
        setDownloadInProgress(true);
        const allSelectionsCached = await awaitSelectionCache(
            streemshots,
            selectedStreemshotIds,
            streemshotsImageDataRef,
        );
        if (allSelectionsCached) {
            await saveAssetsToDisk(
                streemshots,
                recordings,
                selectedStreemshotIds,
                selectedRecordingIds,
                setErrorMessage,
                logger,
                streemshotsImageDataRef,
            );
            setDownloadInProgress(false);
        }
    };

    return (
        <MediaGallerySelectedPopup
            isVisible={selectionSize > 0}
            isVertNavOpen={uiStore.mainNavExpanded}
            isEmbed={isEmbedView}
            isMobile={isMobile}
        >
            <MediaGallerySelectedPopupCard decoratorPosition="side">
                <IconButton
                    data-testid="media-gallery-unselect-selected-button"
                    disabled={selectionSize === 0}
                    iconName="CloseIcon"
                    label="Unselect streemshots"
                    onClick={onUnselect}
                />
                {!isMobile && (
                    <SelectedText as="p" data-testid="media-gallery-selected-count-text">
                        {errorMessage ? (
                            <AppText color="red50">{errorMessage}</AppText>
                        ) : (
                            `${selectionSize} Selected`
                        )}
                    </SelectedText>
                )}
                <Button
                    data-testid="media-gallery-select-all-button"
                    disabled={
                        !selectionVisible ||
                        selectionSize === streemshots.length + recordings.length
                    }
                    variant={isMobile ? 'inline' : 'secondary'}
                    onClick={onSelectAll}
                    style={{ marginLeft: isMobile ? 'auto' : 'unset' }}
                >
                    Select All
                </Button>
                <RightHandButtonRow>
                    {!readOnly && (
                        <>
                            <Button
                                data-testid="media-gallery-open-delete-confirmation-modal-button"
                                disabled={!selectionVisible}
                                variant="secondary"
                                onClick={() => setIsDeleteConfirmationOpen(true)}
                            >
                                Delete
                            </Button>
                            <DeleteConfirmation
                                isOpen={isDeleteConfirmationOpen}
                                onClose={() => setIsDeleteConfirmationOpen(false)}
                                numStreemshots={selectedStreemshotIds.size}
                                numVideos={selectedRecordingIds.size}
                                deleteInProgress={deleteInProgress}
                                handleDelete={handleDelete}
                            />
                        </>
                    )}

                    <Button
                        data-testid="media-gallery-download-button"
                        disabled={downloadInProgress}
                        loading={downloadInProgress}
                        variant={isMobile ? 'inline' : 'secondary'}
                        onClick={handleDownload}
                    >
                        Download
                    </Button>
                </RightHandButtonRow>
            </MediaGallerySelectedPopupCard>
        </MediaGallerySelectedPopup>
    );
};

const MediaGallerySelectedPopupCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    [`@media ${theme.mediaBreakpoints.xsmall}`]: {
        padding: '8px',
    },
}));

const MediaGallerySelectedPopup = styled('div')<{
    isVisible: boolean;
    isVertNavOpen: boolean;
    isEmbed: boolean;
    isMobile: boolean;
}>(({ isVisible, isVertNavOpen, isEmbed, isMobile, theme }) => ({
    zIndex: theme.layers.ui,
    position: 'fixed',
    bottom: '16px',
    right: '16px',
    left: isEmbed || isMobile ? '16px' : isVertNavOpen ? '216px' : '72px',
    transition: 'all 500ms',
    transform: isVisible ? '' : 'translateY(calc(100% + 64px))',
}));

const SelectedText = styled(AppText)`
    margin: 0 32px 0 16px;
`;

const RightHandButtonRow = styled(ButtonRow)(({ theme }) => ({
    marginLeft: 'auto',
    [`@media ${theme.mediaBreakpoints.xsmall}`]: {
        marginLeft: 'unset',
    },
}));
