import { Company } from '../../types/project.types';
import { AppIcon, AppText, Column, Header, LoadingOverlay, styled } from '@streem/ui-react';
import { useObserver } from 'mobx-react';
import { useCompanySettings } from '../../hooks/company_settings_hooks';
import { useGetCompanyStore } from '../../hooks/detail_store_hooks';
import { SettingsPanel } from './settings_panel';
import { scrollIntoViewRefCallback } from '../../util/scroll_into_view_ref_callback';

interface Props {
    company?: Company;
}

export const FeatureLab: React.FC<Props> = () => {
    const companyStore = useGetCompanyStore();
    const companySettingsStore = useCompanySettings();

    return useObserver(() => {
        if (!companySettingsStore || !companySettingsStore.settings) {
            return null;
        }

        if (companySettingsStore.loading || companyStore.loading) {
            return <LoadingOverlay />;
        }

        return (
            <Column data-testId="feature-lab-page">
                <FeatureLabContainer ref={scrollIntoViewRefCallback}>
                    <HeaderContainer>
                        <Header size="mediumLarge">
                            Beta and Experimental features are new features that are not yet
                            generally available to all users
                        </Header>
                        <AppText>
                            <List>
                                <ListElement>
                                    Beta feature are closer to being ready for general release, but
                                    may still contain bugs or unexpected behavior
                                </ListElement>
                                <ListElement>
                                    Experimental features are in the early stages of development and
                                    even less stable or reliable than beta features
                                </ListElement>
                                <ListElement>
                                    Please note that both Beta and Experimental features may be
                                    changed or removed at any time without notice
                                </ListElement>
                            </List>
                        </AppText>
                        <TooltipMessageContainer>
                            <AppIcon name="MessageIcon" style={{ margin: '-6px 10px' }}></AppIcon>
                            <AppText>
                                Try our new features and give us feedback so we can improve them!
                            </AppText>
                        </TooltipMessageContainer>
                    </HeaderContainer>
                    <Column data-testId="feature-lab-list">
                        <SettingsPanel
                            settings={companySettingsStore.betaSettings}
                            title={'Beta'}
                        />
                        <SettingsPanel
                            settings={companySettingsStore.experimentalSettings}
                            title={'Experimental'}
                        />
                    </Column>
                </FeatureLabContainer>
            </Column>
        );
    });
};

const FeatureLabContainer = styled.div`
    padding: 16px;
    font-family: ASAP, sans-serif;
`;

const HeaderContainer = styled('div')(({ theme }) => ({
    margin: '0px 5px 24px 5px',
    padding: '16px',
    width: '70%',
    border: `solid 1px ${theme.colors.grey10}`,
    borderRadius: '7px',
    boxShadow: '5px 5px 5px -5px rgba(0,0,0,0.3);',
}));

const List = styled.ul`
    font-family: inherit;
    padding: 5px;
`;

const ListElement = styled.li`
    list-style: inside;
`;

const TooltipMessageContainer = styled('div')(({ theme }) => ({
    display: 'block',
    padding: '8px',
    margin: '10px 0px 0px 0px',
    textAlign: 'left',
    backgroundColor: theme.colors.grey05,
    borderRadius: '7px',
}));
