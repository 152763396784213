import { CallDetailItems, RemSize } from '@streem/sdk-react';
import { styled, Theme } from '@streem/ui-react';
import { streem } from 'streem-sdk-protobuf';

interface ItemsProps {
    groupName: string;
    items: streem.api.CallDetail.IItem[];
}
export const Items = ({ groupName, items }: ItemsProps): JSX.Element => {
    const titleFontSize = Theme.text.size.notQuiteAllTheWayMediumLarge as RemSize;
    const itemFontSize = Theme.text.size.medium as RemSize;
    const columnsPerRow = items.length === 1 ? 3 : 1;

    return (
        <Wrapper data-testid="post-call-details-items">
            <CallDetailItems
                items={items}
                maxItemsPerRow={4}
                trade={groupName ?? ''}
                widthPercentage={100}
                dataIdPrefix="post-call-details"
                columnsPerRow={columnsPerRow}
                keyValueStyles={{ fontSize: itemFontSize }}
                keyValueTitleStyles={{ fontSize: titleFontSize, marginBottom: '0.5rem' }}
                keyValueTileStyles={{ bottomPadding: '2.25rem' }}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div({
    border: `1px solid ${Theme.colors.grey10}`,
    padding: '1.5rem',
});
