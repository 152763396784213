import { styled } from '@streem/ui-react';
import { CallDetailHeader } from '../reservation/header/call_detail_header';
import { CallDetailTabs } from './call_detail_tabs';

interface CallDetailsWithTabsProps {
    readOnly?: boolean;
}
export const CallDetailsWithTabs = ({ readOnly }: CallDetailsWithTabsProps): JSX.Element => {
    return (
        <Wrapper data-testid="call-details">
            <CallDetailHeader />
            <CallDetailTabs readOnly={readOnly} />
        </Wrapper>
    );
};

// Row gap is only 1.5rem to account for the 1.5rem margin on the bottom of CallDetailHeader
const Wrapper = styled.article`
    display: flex;
    flex-direction: column;
    row-gap: 1.5em;
    margin: 2rem 1.5rem;
`;
