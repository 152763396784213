import { DetailSession } from '@streem/sdk-core';
import { useObservable } from '@streem/sdk-react';
import { streem } from 'streem-sdk-protobuf';
import { useDetailSession } from './detail_session_hooks';
import { useAnonymousShareChecker } from './use_anonymous_share_checker';
import { useGlobalStore } from './use_global_context';

interface NotesData {
    detailSession: DetailSession;
    isAnonymousSharePageYn: boolean;
    roomNoteCharLimit: number;
    roomNote: streem.api.INote;
}
export const useNotes = (): NotesData => {
    const detailSession = useDetailSession();
    const { authStore, companySettingsStore } = useGlobalStore();
    const isAnonymousSharePageYn = useAnonymousShareChecker(authStore);
    const [roomNote] = useObservable(detailSession.note.roomNote);
    const roomNoteCharLimit = companySettingsStore.roomNoteCharLimit;

    return {
        detailSession,
        isAnonymousSharePageYn,
        roomNoteCharLimit,
        roomNote,
    };
};
