/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemInternalApiGroupReservationWaitMetrics,
    StreemInternalApiGroupReservationWaitMetricsFromJSON,
    StreemInternalApiGroupReservationWaitMetricsFromJSONTyped,
    StreemInternalApiGroupReservationWaitMetricsToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemInternalApiSaveGroupAverageCallLengthRequest
 */
export interface StreemInternalApiSaveGroupAverageCallLengthRequest {
    /**
     * 
     * @type {string}
     * @memberof StreemInternalApiSaveGroupAverageCallLengthRequest
     */
    companySid?: string;
    /**
     * 
     * @type {string}
     * @memberof StreemInternalApiSaveGroupAverageCallLengthRequest
     */
    groupSid?: string;
    /**
     * 
     * @type {StreemInternalApiGroupReservationWaitMetrics}
     * @memberof StreemInternalApiSaveGroupAverageCallLengthRequest
     */
    metrics?: StreemInternalApiGroupReservationWaitMetrics;
    /**
     * 
     * @type {string}
     * @memberof StreemInternalApiSaveGroupAverageCallLengthRequest
     */
    updateType?: StreemInternalApiSaveGroupAverageCallLengthRequestUpdateType;
}

export function StreemInternalApiSaveGroupAverageCallLengthRequestFromJSON(json: any): StreemInternalApiSaveGroupAverageCallLengthRequest {
    return StreemInternalApiSaveGroupAverageCallLengthRequestFromJSONTyped(json, false);
}

export function StreemInternalApiSaveGroupAverageCallLengthRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemInternalApiSaveGroupAverageCallLengthRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companySid': !exists(json, 'company_sid') ? undefined : json['company_sid'],
        'groupSid': !exists(json, 'group_sid') ? undefined : json['group_sid'],
        'metrics': !exists(json, 'metrics') ? undefined : StreemInternalApiGroupReservationWaitMetricsFromJSON(json['metrics']),
        'updateType': !exists(json, 'update_type') ? undefined : json['update_type'],
    };
}

export function StreemInternalApiSaveGroupAverageCallLengthRequestToJSON(value?: StreemInternalApiSaveGroupAverageCallLengthRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_sid': value.companySid,
        'group_sid': value.groupSid,
        'metrics': StreemInternalApiGroupReservationWaitMetricsToJSON(value.metrics),
        'update_type': value.updateType,
    };
}

/**
* @export
* @enum {string}
*/
export enum StreemInternalApiSaveGroupAverageCallLengthRequestUpdateType {
    CREATED = 'WAIT_TIME_UPDATE_TYPE_CREATED',
    REQUEUED = 'WAIT_TIME_UPDATE_TYPE_REQUEUED',
    POLLING = 'WAIT_TIME_UPDATE_TYPE_POLLING',
    USERAVAILABILITYUPDATED = 'WAIT_TIME_UPDATE_TYPE_USER_AVAILABILITY_UPDATED',
    GROUPRESERVATIONUPDATED = 'WAIT_TIME_UPDATE_TYPE_GROUP_RESERVATION_UPDATED',
    INVALID = 'WAIT_TIME_UPDATE_TYPE_INVALID'
}


