/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useGlobalStore } from './use_global_context';
import { useAppHistory } from './use_app_history';
import { CancellablePromise } from '../types/project.types';
import { UserCallLogStore } from '../types/call_logs.types';

type MyCallLogFiltersConfig = {
    requestSourceCodes?: string[];
    searchStringFilter?: string;
};

/**
 * Updates the filters on the call log store and triggers a request for new data
 * @param callLogStore - The call log store to update
 * @param filters - The filters to apply to the call log
 */
export const useUpdateMyCallLogFilters = (
    callLogStore: UserCallLogStore,
    filters?: MyCallLogFiltersConfig,
): void => {
    const { authStore } = useGlobalStore();
    const appHistory = useAppHistory();

    useEffect(() => {
        const PAGE_SIZE = 100;
        const previousLocationPathname = appHistory[appHistory.length - 1];
        const navigatingFromCallDetailsPageYn =
            previousLocationPathname && previousLocationPathname.includes('rm_');

        const storedResultsExist = callLogStore.results.length !== 0;

        let promise: CancellablePromise<void>;

        // If we're navigating from the call details page, we need to refresh the call log store
        if (navigatingFromCallDetailsPageYn && storedResultsExist) {
            promise = callLogStore.refresh();
            return;
        } else {
            promise = callLogStore.fetchFirstPage(
                {
                    userSid: authStore.userId,
                    pageSize: PAGE_SIZE,
                    filter: filters.searchStringFilter,
                    callSourceCodes: filters.requestSourceCodes,
                },
                {
                    clearCache: true,
                },
            );
        }

        return () => {
            promise?.cancel();
        };
    }, [
        callLogStore,
        authStore.userId,
        filters.requestSourceCodes.toString(),
        filters.searchStringFilter,
    ]);
};
