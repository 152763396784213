import { useMediaBreakpoint } from '@streem/sdk-react';
import { useDetailSession } from '../../../../hooks/detail_session_hooks';
import {
    MediaGallerySelected,
    MediaGallerySelectedProps,
} from '../../../media_gallery/media_gallery_selected';

type RequiredMediaGalleryProps = Required<
    Pick<
        MediaGallerySelectedProps,
        'recordings' | 'selectedRecordingIds' | 'errorMessage' | 'setErrorMessage' | 'logger'
    >
>;
type RecordingsSelectedPopupProps = RequiredMediaGalleryProps & {
    selectAllRecordings: () => void;
    clearSelectedRecordings: () => void;
    // add this separately to allow it to be optional
    readOnly?: boolean;
};
export const RecordingsSelectedPopup = ({
    recordings,
    selectedRecordingIds,
    readOnly,
    clearSelectedRecordings,
    selectAllRecordings,
    errorMessage,
    setErrorMessage,
    logger,
}: RecordingsSelectedPopupProps): JSX.Element => {
    const detailSession = useDetailSession();
    const isMobile = useMediaBreakpoint().isMobile;
    const selectionSize = selectedRecordingIds.size;
    const selectionVisible = selectionSize > 0 || isMobile;

    const onSelectAll = () => {
        setErrorMessage('');
        selectAllRecordings();
    };
    const onUnselect = () => {
        setErrorMessage('');
        clearSelectedRecordings();
    };

    return (
        <MediaGallerySelected
            detailSession={detailSession}
            recordings={recordings}
            selectedRecordingIds={selectedRecordingIds}
            selectionSize={selectionSize}
            isMobile={isMobile}
            selectionVisible={selectionVisible}
            readOnly={readOnly}
            onUnselect={onUnselect}
            onSelectAll={onSelectAll}
            clearSelection={clearSelectedRecordings}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            logger={logger}
        />
    );
};
