import { google, streem } from 'streem-sdk-protobuf';
import { WallItemSubtype } from '@streem/sdk-types';

const AvailabilityStatus = streem.api.AvailabilityStatus;
const GroupReservationStatus = streem.api.GroupReservationStatus;

export const convertProtobufToSeconds = (time: google.protobuf.ITimestamp) => {
    return time?.seconds?.low || 0 + (time?.nanos || 0) / 1000000000;
};

export function getReservationExpirationMessage(minutesToDisplay: number) {
    return `This reservation will expire in ${minutesToDisplay < 1 ? 'less than ' : ''}${
        minutesToDisplay < 1 ? 1 : minutesToDisplay
    } minute${minutesToDisplay > 1 ? 's' : ''}.`;
}

export const shouldShowBanner = (
    availabilityStatusWallItem:
        | WallItemSubtype<streem.user.UserWallItem, 'userAvailability'>
        | undefined,
    groupReservationWallItem:
        | WallItemSubtype<streem.user.UserWallItem, 'lastGroupReservation'>
        | undefined,
): boolean => {
    return (
        availabilityStatusWallItem?.availabilityStatus ===
            AvailabilityStatus.AVAILABILITY_STATUS_RESERVED &&
        groupReservationWallItem &&
        [
            GroupReservationStatus.GROUP_RESERVATION_STATUS_MATCHED,
            GroupReservationStatus.GROUP_RESERVATION_STATUS_INITIATED,
        ].includes(groupReservationWallItem?.reservationStatus)
    );
};

export const shouldClearBanner = (
    availabilityStatusWallItem:
        | WallItemSubtype<streem.user.UserWallItem, 'userAvailability'>
        | undefined,
    groupReservationWallItem:
        | WallItemSubtype<streem.user.UserWallItem, 'lastGroupReservation'>
        | undefined,
): boolean => {
    return (
        availabilityStatusWallItem?.availabilityStatus !==
            AvailabilityStatus.AVAILABILITY_STATUS_RESERVED ||
        !groupReservationWallItem ||
        ![
            GroupReservationStatus.GROUP_RESERVATION_STATUS_MATCHED,
            GroupReservationStatus.GROUP_RESERVATION_STATUS_INITIATED,
        ].includes(groupReservationWallItem?.reservationStatus)
    );
};

export const ACTIVE_MODAL_MESSAGES = {
    UPDATE_AVAILABILITY_STATUS: `It looks like you've been away for a while. Would you like to update your
                    availability status?`,
    BAD_NETWORK_STATUS:
        'Due to bad network connectivity, we have changed your status to Not Ready.',
};
