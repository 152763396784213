import { APITypes } from '@streem/api';
import { UserInfo } from '@streem/sdk-core';
import { AppText, CompanyWatermark, Column, Flex, Row, styled, Theme } from '@streem/ui-react';
import { SyntheticEvent } from 'react';
import { streem } from 'streem-sdk-protobuf';

interface LogoCustomerExpertProps {
    callSource?: APITypes.StreemApiCallSource;
    callDetail?: streem.api.ICallDetail;
    expert?: UserInfo;
}
export const LogoCustomerExpert = ({
    callSource,
    callDetail,
    expert,
}: LogoCustomerExpertProps): JSX.Element => {
    return (
        <LogoCustomerExpertWrapper>
            <LogoSourceNameCustomer>
                {callSource?.logoUrl && <Logo logoUrl={callSource?.logoUrl} />}
                {callSource?.name && (
                    <AppText bold size="xxlarge" data-testid="detail-source-name">
                        {callSource?.name}
                    </AppText>
                )}
                {callDetail.customer && (
                    <CustomerWrapper>
                        <AppText color="grey60" style={{ fontSize: '.875rem' }}>
                            Customer
                        </AppText>
                        <AppText
                            semibold
                            size="mediumLarge"
                            overflowWrap="anywhere"
                            data-testid="detail-customer-name"
                        >
                            {callDetail.customer.name}
                        </AppText>
                    </CustomerWrapper>
                )}
            </LogoSourceNameCustomer>
            {expert && (
                <ExpertWrapper>
                    <AppText>Call with&nbsp;</AppText>
                    {expert.photoURL && (
                        <InlineImg src={expert.photoURL} data-testid="detail-expert-photo" />
                    )}
                    <AppText data-testid="detail-expert-name">{expert.name}</AppText>
                </ExpertWrapper>
            )}
        </LogoCustomerExpertWrapper>
    );
};

interface LogoProps {
    logoUrl: string;
}
const Logo = ({ logoUrl }: LogoProps): JSX.Element => {
    const handleLogoError = (event: SyntheticEvent<HTMLImageElement>) => {
        const element: HTMLImageElement = event.target as HTMLImageElement;
        element.style.display = 'none';
    };

    return (
        <CompanyWatermark
            src={logoUrl}
            onError={handleLogoError}
            data-testid="detail-source-logo"
        />
    );
};

const LogoCustomerExpertWrapper = styled(Row)({
    justifyContent: 'space-between',
    width: '100%',
    backgroundColor: Theme.colors.grey05,
    padding: '.75rem 1rem',
    columnGap: '.5rem',
});

const LogoSourceNameCustomer = styled(Flex)({
    alignItems: 'center',
    columnGap: '1rem',
});

const ExpertWrapper = styled.span({
    alignSelf: 'flex-end',
});

const CustomerWrapper = styled(Column)({
    marginLeft: '.5rem',
});

const InlineImg = styled('img')({
    display: 'inline-block',
    height: '1rem',
    width: '1rem',
    borderRadius: '50%',
    marginRight: '.3rem',
    transform: 'translateY(.125rem)',
});
