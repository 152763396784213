/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StreemApiEventsArtifactCreated,
    StreemApiEventsArtifactCreatedFromJSON,
    StreemApiEventsArtifactCreatedFromJSONTyped,
    StreemApiEventsArtifactCreatedToJSON,
    StreemApiEventsArtifactDeleted,
    StreemApiEventsArtifactDeletedFromJSON,
    StreemApiEventsArtifactDeletedFromJSONTyped,
    StreemApiEventsArtifactDeletedToJSON,
    StreemApiEventsArtifactUpdated,
    StreemApiEventsArtifactUpdatedFromJSON,
    StreemApiEventsArtifactUpdatedFromJSONTyped,
    StreemApiEventsArtifactUpdatedToJSON,
    StreemApiEventsGroupReservationCreated,
    StreemApiEventsGroupReservationCreatedFromJSON,
    StreemApiEventsGroupReservationCreatedFromJSONTyped,
    StreemApiEventsGroupReservationCreatedToJSON,
    StreemApiEventsGroupReservationUpdated,
    StreemApiEventsGroupReservationUpdatedFromJSON,
    StreemApiEventsGroupReservationUpdatedFromJSONTyped,
    StreemApiEventsGroupReservationUpdatedToJSON,
    StreemApiEventsGroupWaitTimeUpdated,
    StreemApiEventsGroupWaitTimeUpdatedFromJSON,
    StreemApiEventsGroupWaitTimeUpdatedFromJSONTyped,
    StreemApiEventsGroupWaitTimeUpdatedToJSON,
    StreemApiEventsRoomArchived,
    StreemApiEventsRoomArchivedFromJSON,
    StreemApiEventsRoomArchivedFromJSONTyped,
    StreemApiEventsRoomArchivedToJSON,
    StreemApiEventsRoomCreated,
    StreemApiEventsRoomCreatedFromJSON,
    StreemApiEventsRoomCreatedFromJSONTyped,
    StreemApiEventsRoomCreatedToJSON,
    StreemApiEventsRoomNoteCreated,
    StreemApiEventsRoomNoteCreatedFromJSON,
    StreemApiEventsRoomNoteCreatedFromJSONTyped,
    StreemApiEventsRoomNoteCreatedToJSON,
    StreemApiEventsRoomNoteDeleted,
    StreemApiEventsRoomNoteDeletedFromJSON,
    StreemApiEventsRoomNoteDeletedFromJSONTyped,
    StreemApiEventsRoomNoteDeletedToJSON,
    StreemApiEventsRoomNoteUpdated,
    StreemApiEventsRoomNoteUpdatedFromJSON,
    StreemApiEventsRoomNoteUpdatedFromJSONTyped,
    StreemApiEventsRoomNoteUpdatedToJSON,
    StreemApiEventsRoomParticipantCreated,
    StreemApiEventsRoomParticipantCreatedFromJSON,
    StreemApiEventsRoomParticipantCreatedFromJSONTyped,
    StreemApiEventsRoomParticipantCreatedToJSON,
    StreemApiEventsRoomParticipantUpdated,
    StreemApiEventsRoomParticipantUpdatedFromJSON,
    StreemApiEventsRoomParticipantUpdatedFromJSONTyped,
    StreemApiEventsRoomParticipantUpdatedToJSON,
    StreemApiEventsRoomUpdated,
    StreemApiEventsRoomUpdatedFromJSON,
    StreemApiEventsRoomUpdatedFromJSONTyped,
    StreemApiEventsRoomUpdatedToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiWebhookEventPayload
 */
export interface StreemApiWebhookEventPayload {
    /**
     * 
     * @type {StreemApiEventsRoomCreated}
     * @memberof StreemApiWebhookEventPayload
     */
    roomCreated?: StreemApiEventsRoomCreated;
    /**
     * 
     * @type {StreemApiEventsRoomUpdated}
     * @memberof StreemApiWebhookEventPayload
     */
    roomUpdated?: StreemApiEventsRoomUpdated;
    /**
     * 
     * @type {StreemApiEventsRoomArchived}
     * @memberof StreemApiWebhookEventPayload
     */
    roomArchived?: StreemApiEventsRoomArchived;
    /**
     * 
     * @type {StreemApiEventsRoomParticipantCreated}
     * @memberof StreemApiWebhookEventPayload
     */
    roomParticipantCreated?: StreemApiEventsRoomParticipantCreated;
    /**
     * 
     * @type {StreemApiEventsRoomParticipantUpdated}
     * @memberof StreemApiWebhookEventPayload
     */
    roomParticipantUpdated?: StreemApiEventsRoomParticipantUpdated;
    /**
     * 
     * @type {StreemApiEventsGroupReservationCreated}
     * @memberof StreemApiWebhookEventPayload
     */
    groupReservationCreated?: StreemApiEventsGroupReservationCreated;
    /**
     * 
     * @type {StreemApiEventsGroupReservationUpdated}
     * @memberof StreemApiWebhookEventPayload
     */
    groupReservationUpdated?: StreemApiEventsGroupReservationUpdated;
    /**
     * 
     * @type {StreemApiEventsGroupWaitTimeUpdated}
     * @memberof StreemApiWebhookEventPayload
     */
    groupWaitTimeUpdated?: StreemApiEventsGroupWaitTimeUpdated;
    /**
     * 
     * @type {StreemApiEventsArtifactCreated}
     * @memberof StreemApiWebhookEventPayload
     */
    artifactCreated?: StreemApiEventsArtifactCreated;
    /**
     * 
     * @type {StreemApiEventsArtifactUpdated}
     * @memberof StreemApiWebhookEventPayload
     */
    artifactUpdated?: StreemApiEventsArtifactUpdated;
    /**
     * 
     * @type {StreemApiEventsArtifactDeleted}
     * @memberof StreemApiWebhookEventPayload
     */
    artifactDeleted?: StreemApiEventsArtifactDeleted;
    /**
     * 
     * @type {StreemApiEventsRoomNoteCreated}
     * @memberof StreemApiWebhookEventPayload
     */
    roomNoteCreated?: StreemApiEventsRoomNoteCreated;
    /**
     * 
     * @type {StreemApiEventsRoomNoteUpdated}
     * @memberof StreemApiWebhookEventPayload
     */
    roomNoteUpdated?: StreemApiEventsRoomNoteUpdated;
    /**
     * 
     * @type {StreemApiEventsRoomNoteDeleted}
     * @memberof StreemApiWebhookEventPayload
     */
    roomNoteDeleted?: StreemApiEventsRoomNoteDeleted;
}

export function StreemApiWebhookEventPayloadFromJSON(json: any): StreemApiWebhookEventPayload {
    return StreemApiWebhookEventPayloadFromJSONTyped(json, false);
}

export function StreemApiWebhookEventPayloadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiWebhookEventPayload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomCreated': !exists(json, 'room_created') ? undefined : StreemApiEventsRoomCreatedFromJSON(json['room_created']),
        'roomUpdated': !exists(json, 'room_updated') ? undefined : StreemApiEventsRoomUpdatedFromJSON(json['room_updated']),
        'roomArchived': !exists(json, 'room_archived') ? undefined : StreemApiEventsRoomArchivedFromJSON(json['room_archived']),
        'roomParticipantCreated': !exists(json, 'room_participant_created') ? undefined : StreemApiEventsRoomParticipantCreatedFromJSON(json['room_participant_created']),
        'roomParticipantUpdated': !exists(json, 'room_participant_updated') ? undefined : StreemApiEventsRoomParticipantUpdatedFromJSON(json['room_participant_updated']),
        'groupReservationCreated': !exists(json, 'group_reservation_created') ? undefined : StreemApiEventsGroupReservationCreatedFromJSON(json['group_reservation_created']),
        'groupReservationUpdated': !exists(json, 'group_reservation_updated') ? undefined : StreemApiEventsGroupReservationUpdatedFromJSON(json['group_reservation_updated']),
        'groupWaitTimeUpdated': !exists(json, 'group_wait_time_updated') ? undefined : StreemApiEventsGroupWaitTimeUpdatedFromJSON(json['group_wait_time_updated']),
        'artifactCreated': !exists(json, 'artifact_created') ? undefined : StreemApiEventsArtifactCreatedFromJSON(json['artifact_created']),
        'artifactUpdated': !exists(json, 'artifact_updated') ? undefined : StreemApiEventsArtifactUpdatedFromJSON(json['artifact_updated']),
        'artifactDeleted': !exists(json, 'artifact_deleted') ? undefined : StreemApiEventsArtifactDeletedFromJSON(json['artifact_deleted']),
        'roomNoteCreated': !exists(json, 'room_note_created') ? undefined : StreemApiEventsRoomNoteCreatedFromJSON(json['room_note_created']),
        'roomNoteUpdated': !exists(json, 'room_note_updated') ? undefined : StreemApiEventsRoomNoteUpdatedFromJSON(json['room_note_updated']),
        'roomNoteDeleted': !exists(json, 'room_note_deleted') ? undefined : StreemApiEventsRoomNoteDeletedFromJSON(json['room_note_deleted']),
    };
}

export function StreemApiWebhookEventPayloadToJSON(value?: StreemApiWebhookEventPayload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_created': StreemApiEventsRoomCreatedToJSON(value.roomCreated),
        'room_updated': StreemApiEventsRoomUpdatedToJSON(value.roomUpdated),
        'room_archived': StreemApiEventsRoomArchivedToJSON(value.roomArchived),
        'room_participant_created': StreemApiEventsRoomParticipantCreatedToJSON(value.roomParticipantCreated),
        'room_participant_updated': StreemApiEventsRoomParticipantUpdatedToJSON(value.roomParticipantUpdated),
        'group_reservation_created': StreemApiEventsGroupReservationCreatedToJSON(value.groupReservationCreated),
        'group_reservation_updated': StreemApiEventsGroupReservationUpdatedToJSON(value.groupReservationUpdated),
        'group_wait_time_updated': StreemApiEventsGroupWaitTimeUpdatedToJSON(value.groupWaitTimeUpdated),
        'artifact_created': StreemApiEventsArtifactCreatedToJSON(value.artifactCreated),
        'artifact_updated': StreemApiEventsArtifactUpdatedToJSON(value.artifactUpdated),
        'artifact_deleted': StreemApiEventsArtifactDeletedToJSON(value.artifactDeleted),
        'room_note_created': StreemApiEventsRoomNoteCreatedToJSON(value.roomNoteCreated),
        'room_note_updated': StreemApiEventsRoomNoteUpdatedToJSON(value.roomNoteUpdated),
        'room_note_deleted': StreemApiEventsRoomNoteDeletedToJSON(value.roomNoteDeleted),
    };
}


