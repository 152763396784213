import { AppText, Box, styled } from '@streem/ui-react';
import {
    TranscriptEntry,
    convertTimestampToFormattedTime,
} from '../../hooks/use_raw_transcription';

interface Props {
    rawTranscription: TranscriptEntry[];
}

export const CallTranscription = ({ rawTranscription }: Props) => {
    return (
        <CallTranscriptionContainer data-testid="transcription-container">
            {rawTranscription.map(entry => (
                <TranscriptionText as="p" key={entry.timestamp}>
                    {convertTimestampToFormattedTime(entry.timestamp)}: {entry.text}
                </TranscriptionText>
            ))}
        </CallTranscriptionContainer>
    );
};

export const CallTranscriptionContainer = styled(Box)(() => ({
    overflow: 'scroll',
    padding: '10px',
}));

const TranscriptionText = styled(AppText)(() => ({
    padding: '5px',
}));
