import { observer } from 'mobx-react';
import { FC, useEffect, useState } from 'react';
import { Subheader, useTheme, Column, AppIcon, styled, ToastTypes } from '@streem/ui-react';
import { ListViewWithPagination } from '../../components/lists/list_view_with_pagination';
import { FlexCentered } from '../../forms/shared_form.styles';
import { useGetCompanyCallLogStore } from '../../hooks/list_store_hooks';
import { useActiveCompanyCode } from '../../hooks/use_active_company_code';
import {
    CallLogColumnProps,
    CallLogRow,
    columnBuilder,
} from '../../components/calls/call_log_columns';
import { Redirect, useHistory } from 'react-router-dom';
import { joinPaths } from '../../util/routing';
import {
    CompanySettingsProvider,
    createCompanySettingsStore,
} from '../../hooks/company_settings_hooks';
import { useGetEmbedEnabledSettings } from '../../hooks/use_get_embed_enabled_settings';
import appLogger from '../../util/logging/app_logger';
import { useGlobalStore } from '../../hooks/use_global_context';
import { StreemAPI } from '@streem/api';
import { ArchiveConfirmationModal } from '../../components/calls/archive_confirmation_modal';
import { recordElementClicked } from '@streem/analytics';
import { useUpdateTeamCallLogFilters } from '../../hooks/use_update_team_call_log_filters';

interface TeamCallLogPageProps {
    searchString: string;
    clearSearch: () => void;
}

export const TeamCallLogPage: FC<TeamCallLogPageProps> = observer(
    ({ searchString, clearSearch }) => {
        const log = appLogger.extend('Team Call Log');
        const theme = useTheme();
        const history = useHistory();
        const companyCode = useActiveCompanyCode();
        const companySettingsStore = createCompanySettingsStore(companyCode);
        const { isEmbedView } = useGetEmbedEnabledSettings();
        const [isArchiveConfirmationModalOpen, setIsArchiveConfirmationModalOpen] = useState(false);
        const { authStore, uiStore } = useGlobalStore();
        const companyCallLogStore = useGetCompanyCallLogStore();
        const [activeRecord, setActiveRecord] = useState<CallLogRow>();
        const canDeactivateCall =
            authStore.role === 'COMPANY_ADMIN' && companySettingsStore.callDeactivateEnabled;

        function buildTeamCallLogColumns(props: CallLogColumnProps) {
            const cols = [
                columnBuilder.customerColumn(props),
                columnBuilder.teamMemberColumn(),
                columnBuilder.artifactColumn(props),
                columnBuilder.durationColumn(),
                columnBuilder.dateColumn(),
            ];

            if (props.showDeactivateColumn) {
                cols.push(columnBuilder.dropdownMenuColumn(props));
            }

            return cols;
        }

        useEffect(() => {
            const promise = companySettingsStore.loadSettings();
            return () => promise.cancel();
        }, [companyCode, companySettingsStore]);

        useUpdateTeamCallLogFilters(companyCallLogStore, {
            requestGroupNames: uiStore.appliedGroupFilters,
            requestSourceCodes: uiStore.appliedSourceFiltersSelected,
            searchStringFilter: searchString,
            clearCache: uiStore.shouldClearCache,
        });

        useEffect(() => {
            return () => {
                uiStore.resetCacheState();
            };
        }, [uiStore]);

        const handleRoomArchive = (row: CallLogRow) => {
            setIsArchiveConfirmationModalOpen(true);
            setActiveRecord(row);
        };

        const archiveRoom = async () => {
            try {
                recordElementClicked('deactivate-customer-modal-confirm');
                setIsArchiveConfirmationModalOpen(false);
                log.info(`Archiving room with roomId=${activeRecord?.roomSid}`);
                const response =
                    await StreemAPI.rooms.archiveAndDeleteRoomAndDeleteNonExpertParticipant(
                        activeRecord?.roomSid as string,
                    );
                log.info(
                    `Successfully archived room with roomId=${
                        activeRecord?.roomSid
                    } response=<${JSON.stringify(response)}>`,
                );
                uiStore.addToast({
                    content: `Successfully reported this caller`,
                    flavor: ToastTypes.SUCCESS,
                    id: `room-archive-${activeRecord?.roomSid}`,
                });
                companyCallLogStore.refresh();
            } catch (e) {
                log.error('Failed to archive room: ', e);
                uiStore.addToast({
                    flavor: ToastTypes.ERROR,
                    id: `room-archive-error-${activeRecord?.roomSid}`,
                    content: `There was an error. Please try again.`,
                });
            }
        };

        const rowClickUrl = (row: CallLogRow) => {
            const url = joinPaths(history.location.pathname, row.roomSid);
            history.push(url);
        };

        const isRowClickable = (row: CallLogRow) =>
            row.roomSid && row.callStatus === 'STATUS_SUCCESSFUL';

        const hideGPSLocations = !companySettingsStore.gpsEnabled;
        const callLogColumns =
            isEmbedView || !companySettingsStore.callFavoritingEnabled
                ? [
                      ...buildTeamCallLogColumns({
                          theme,
                          hideGPSLocations,
                          handleRoomArchive,
                          showDeactivateColumn: canDeactivateCall,
                      }),
                  ]
                : [
                      columnBuilder.favoriteCallColumn(() => companyCallLogStore.refresh()),
                      ...buildTeamCallLogColumns({
                          theme,
                          hideGPSLocations,
                          handleRoomArchive,
                          showDeactivateColumn: canDeactivateCall,
                      }),
                  ];
        const callLogRecords: CallLogRow[] = companyCallLogStore.results.map(v => ({
            key: v.roomSid!,
            ...v,
        }));
        if (companyCallLogStore.lastError && companyCallLogStore.lastError.status === 500) {
            return <Redirect to="/error" />;
        }
        return (
            <CompanySettingsProvider value={companySettingsStore}>
                <ListViewWithPagination
                    initialScrollPosition={companyCallLogStore.scrollPosition}
                    store={companyCallLogStore}
                    data-testid="team-call-log"
                    loading={companyCallLogStore.loading}
                    loadingMore={companyCallLogStore.loadingMore}
                    linkTo={rowClickUrl}
                    canRowClick={isRowClickable}
                    data={callLogRecords}
                    buildRowTestId={row => 'team-call-log-row-' + row.roomSid}
                    columns={callLogColumns}
                    gridTemplateColumns={`${
                        isEmbedView || !companySettingsStore.callFavoritingEnabled
                            ? ''
                            : 'minmax(150px, 200px)'
                    } minmax(200px, 400px) minmax(300px, 1fr) 250px 170px 170px
                        ${canDeactivateCall && !isEmbedView ? '77px' : ''}`}
                />
                {!companyCallLogStore.loading && callLogRecords.length === 0 && searchString && (
                    <Column style={{ alignItems: 'center', marginTop: '100px' }}>
                        <AppIcon name="SearchGlassIcon" size="xlarge" color={theme.colors.grey10} />
                        <Subheader
                            style={{
                                maxWidth: '25vw',
                                overflowWrap: 'break-word',
                                marginTop: '100px',
                            }}
                            bold={true}
                        >
                            No results for '
                            {searchString.length < 100
                                ? searchString
                                : searchString.slice(0, 100) + '...'}
                            ' found.
                        </Subheader>
                        <TextButton onClick={clearSearch}>Clear search</TextButton>
                    </Column>
                )}
                {!companyCallLogStore.loading && callLogRecords.length === 0 && !searchString && (
                    <FlexCentered>
                        <Subheader>No Streem calls have been made yet.</Subheader>
                    </FlexCentered>
                )}
                <ArchiveConfirmationModal
                    isOpen={isArchiveConfirmationModalOpen}
                    closeModal={() => {
                        recordElementClicked('deactivate-customer-modal-cancel');
                        setIsArchiveConfirmationModalOpen(false);
                    }}
                    handleArchive={archiveRoom}
                />
            </CompanySettingsProvider>
        );
    },
);

const TextButton = styled.p(({ theme }) => ({
    color: theme.colors.aqua,
    marginTop: theme.spacing.m,
    ':hover': {
        color: theme.colors.navyBlue,
        cursor: 'pointer',
    },
}));
