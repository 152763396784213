import { Bookmark } from '@streem/sdk-core';

export const getTimeStampFromBookmark = (bookmark: Bookmark): number => {
    return (
        bookmark.bookmark.timestamp?.seconds.low +
        bookmark.bookmark.timestamp?.nanos / 1_000_000_000
    );
};

export const sortBookmarks = (bookmarks: Bookmark[]): Bookmark[] => {
    return bookmarks.sort((a, b) => {
        const aTimestamp = getTimeStampFromBookmark(a);
        const bTimetamp = getTimeStampFromBookmark(b);
        if (aTimestamp > bTimetamp) {
            return 1;
        } else {
            return -1;
        }
    });
};
