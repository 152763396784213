import { useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTheme, Subheader } from '@streem/ui-react';
import { APITypes } from '@streem/api';
import { observer } from 'mobx-react';
import { columnBuilder, CallLogColumnProps } from '../../components/calls/call_log_columns';
import { FlexCentered } from '../../forms/shared_form.styles';
import { useGlobalStore } from '../../hooks/use_global_context';
import { useGetUserCallLogStore } from '../../hooks/list_store_hooks';
import { joinPaths } from '../../util/routing';
import { ListViewWithPagination } from '../../components/lists/list_view_with_pagination';
import { getCompanyRoomOutcomeConfig } from '../../hooks/use_room_outcome_form_config';
import { useGetEmbedEnabledSettings } from '../../hooks/use_get_embed_enabled_settings';
import { useGetCompanyStore } from '../../hooks/detail_store_hooks';
import { useUpdateMyCallLogFilters } from '../../hooks/use_update_my_calls_filters';

function buildMyCallLogColumns(props: CallLogColumnProps) {
    const cols = [
        columnBuilder.customerColumn(props),
        columnBuilder.artifactColumn(props),
        columnBuilder.durationColumn(),
        columnBuilder.dateColumn(),
    ];

    return cols;
}

interface MyCallLogPageProps {
    searchString: string;
}

interface CallLogRow extends APITypes.StreemApiCallLogEntry {
    key: string;
}

export const MyCallLogPage = observer(({ searchString }: MyCallLogPageProps) => {
    const theme = useTheme();
    const history = useHistory();
    const { companySettingsStore, uiStore } = useGlobalStore();
    const userCallLogStore = useGetUserCallLogStore();
    const { isEmbedView } = useGetEmbedEnabledSettings();
    const companyStore = useGetCompanyStore();

    const [showCallOutcomeWarning, setShowCallOutcomeWarning] = useState(false);
    useEffect(() => {
        async function displayCallOutcomeWarning() {
            try {
                await getCompanyRoomOutcomeConfig(companyStore?.result?.sid);
                setShowCallOutcomeWarning(true);
            } catch (error) {
                setShowCallOutcomeWarning(false);
            }
        }

        if (companySettingsStore.roomOutcomeFeatureEnabled && companyStore && companyStore.result) {
            displayCallOutcomeWarning();
        }
    }, [companySettingsStore, companyStore]);

    useUpdateMyCallLogFilters(userCallLogStore, {
        requestSourceCodes: uiStore.appliedSourceFiltersSelected,
        searchStringFilter: searchString,
    });

    const rowClickUrl = (row: CallLogRow) => {
        const url = joinPaths(history.location.pathname, row.roomSid!);
        history.push(url);
    };

    const isRowClickable = (row: CallLogRow) =>
        row.roomSid && row.callStatus === 'STATUS_SUCCESSFUL';

    const callLogRecords: CallLogRow[] = userCallLogStore.results.map(v => ({
        key: v.roomSid!,
        ...v,
    }));

    if (userCallLogStore.lastError && userCallLogStore.lastError.status === 500) {
        return <Redirect to="/error" />;
    }

    if (!userCallLogStore.loading && callLogRecords.length === 0 && searchString.length) {
        return (
            <FlexCentered>
                <Subheader>No Streem calls were found for {searchString}.</Subheader>
            </FlexCentered>
        );
    }

    if (!userCallLogStore.loading && callLogRecords.length === 0) {
        return (
            <FlexCentered>
                <Subheader>You have not made any calls yet.</Subheader>
            </FlexCentered>
        );
    }

    const hideGPSLocations = !companySettingsStore.gpsEnabled;

    const columns =
        isEmbedView || !companySettingsStore.callFavoritingEnabled
            ? [
                  ...buildMyCallLogColumns({
                      theme,
                      hideGPSLocations,
                      ...(!isEmbedView && { showCallOutcomeWarning }),
                  }),
              ]
            : [
                  columnBuilder.favoriteCallColumn(() => userCallLogStore.refresh()),
                  ...buildMyCallLogColumns({
                      theme,
                      hideGPSLocations,
                      showCallOutcomeWarning,
                  }),
              ];
    return (
        <ListViewWithPagination
            initialScrollPosition={userCallLogStore.scrollPosition}
            store={userCallLogStore}
            data-testid="my-call-log"
            loading={userCallLogStore.loading}
            linkTo={rowClickUrl}
            canRowClick={isRowClickable}
            data={callLogRecords}
            buildRowTestId={row => 'my-call-log-row-' + row.roomSid}
            columns={columns}
            gridTemplateColumns={`${
                isEmbedView || !companySettingsStore.callFavoritingEnabled
                    ? ''
                    : 'minmax(150px, 200px)'
            } minmax(300px, 1fr) 250px 170px 170px`}
        />
    );
});
