/* tslint:disable */
/* eslint-disable */
/**
 * Streem API
 * The Streem API gives you access to information captured during Streem calls.  You can retrieve artifacts such as recordings, streemshots (including captured model/serial numbers), and much more. 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: support@streem.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GoogleProtobufDuration,
    GoogleProtobufDurationFromJSON,
    GoogleProtobufDurationFromJSONTyped,
    GoogleProtobufDurationToJSON,
} from './';

/**
 * 
 * @export
 * @interface StreemApiPriorityWaitTime
 */
export interface StreemApiPriorityWaitTime {
    /**
     * 
     * @type {number}
     * @memberof StreemApiPriorityWaitTime
     */
    priority?: number;
    /**
     * 
     * @type {Date}
     * @memberof StreemApiPriorityWaitTime
     */
    estimatedWaitUntil?: Date;
    /**
     * 
     * @type {GoogleProtobufDuration}
     * @memberof StreemApiPriorityWaitTime
     */
    estimatedWaitTime?: GoogleProtobufDuration;
}

export function StreemApiPriorityWaitTimeFromJSON(json: any): StreemApiPriorityWaitTime {
    return StreemApiPriorityWaitTimeFromJSONTyped(json, false);
}

export function StreemApiPriorityWaitTimeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StreemApiPriorityWaitTime {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'estimatedWaitUntil': !exists(json, 'estimated_wait_until') ? undefined : (new Date(json['estimated_wait_until'])),
        'estimatedWaitTime': !exists(json, 'estimated_wait_time') ? undefined : GoogleProtobufDurationFromJSON(json['estimated_wait_time']),
    };
}

export function StreemApiPriorityWaitTimeToJSON(value?: StreemApiPriorityWaitTime | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priority': value.priority,
        'estimated_wait_until': value.estimatedWaitUntil === undefined ? undefined : (value.estimatedWaitUntil.toISOString()),
        'estimated_wait_time': GoogleProtobufDurationToJSON(value.estimatedWaitTime),
    };
}


