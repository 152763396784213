import { Recording, Transcription } from '@streem/sdk-core';
import { useMediaBreakpoint } from '@streem/sdk-react';
import { Column, styled, Theme } from '@streem/ui-react';
import { useObserver } from 'mobx-react';
import { RecordingThumbnail } from '../../../media_gallery/thumbnail';
import { useGlobalStore } from '../../../../hooks/use_global_context';
import { ReactNode, useState } from 'react';
import { recordArtifactViewed } from '@streem/analytics';
import { isRecordingPending } from '../../../../util/artifacts';
import { Header } from './shared_styled_components';
import { PendingVideo } from './pending_video';
import { RecordingsSelectedPopup } from './recordings_selected_popup';
import appLogger from '../../../../util/logging/app_logger';

export interface VideoTileProps {
    selectedRecordingIds: string[];
    handleSelectRecording: (recordingId: string) => void;
    clearRecordings: () => void;
    selectAllRecordings: () => void;
    recordings: Recording[];
    roomSid: string;
    readOnly?: boolean;
}
export const VideoTile = ({
    selectedRecordingIds,
    handleSelectRecording,
    clearRecordings,
    selectAllRecordings,
    recordings,
    roomSid,
    readOnly,
}: VideoTileProps): JSX.Element => {
    const { authStore } = useGlobalStore();
    const { isMobile } = useMediaBreakpoint();

    const onPlay = (recordingId: string): void => {
        recordArtifactViewed({
            artifactSid: recordingId,
            roomSid: roomSid,
            recording: {},
        });
    };

    // Note: based on observation, it looks like there is only one recording per room, but the
    //    code is written to handle multiple recordings. If any of the recordings are pending,
    //    treat them all as pending.
    const someRecordingsPendingYn = isRecordingPending(recordings);
    const showSelectRecordingYn = selectedRecordingIds.length > 0 || isMobile;

    return useObserver(() => (
        <VideoTileView
            recordings={recordings}
            isAdmin={authStore.isAdmin}
            selectedRecordingIds={selectedRecordingIds}
            toggleSelectRecording={handleSelectRecording}
            clearRecordings={clearRecordings}
            selectAllRecordings={selectAllRecordings}
            onPlay={onPlay}
            someRecordingsPendingYn={someRecordingsPendingYn}
            showSelectRecordingYn={showSelectRecordingYn}
            roomSid={roomSid}
            readOnly={readOnly}
        />
    ));
};

interface VideoTileViewProps {
    recordings: Recording[];
    isAdmin: boolean;
    selectedRecordingIds: string[];
    toggleSelectRecording: (recordingId: string) => void;
    clearRecordings: () => void;
    selectAllRecordings: () => void;
    transcription?: Transcription;
    onPlay: (recordingId: string) => void;
    someRecordingsPendingYn: boolean;
    showSelectRecordingYn: boolean;
    roomSid: string;
    readOnly?: boolean;
}
const VideoTileView = ({
    recordings,
    isAdmin,
    selectedRecordingIds,
    toggleSelectRecording,
    clearRecordings,
    selectAllRecordings,
    transcription,
    onPlay,
    someRecordingsPendingYn,
    showSelectRecordingYn,
    roomSid,
    readOnly,
}: VideoTileViewProps): JSX.Element => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    let mainComponent: ReactNode;
    if (recordings.length === 0) {
        mainComponent = <NoRecordings />;
    } else if (someRecordingsPendingYn) {
        mainComponent = (
            <PendingVideo
                recordings={recordings}
                showSelectRecordingYn={showSelectRecordingYn}
                roomSid={roomSid}
            />
        );
    } else {
        mainComponent = recordings.map(recording => {
            const onSelect = () => toggleSelectRecording(recording.id);
            const onPressPlay = () => onPlay(recording.id);
            return (
                <RecordingThumbnail
                    key={recording.id}
                    artifact={recording}
                    canSelect={isAdmin}
                    isSelected={selectedRecordingIds.includes(recording.id)}
                    isSelectVisible={false}
                    onSelect={onSelect}
                    transcriptionArtifact={transcription}
                    onPlay={onPressPlay}
                />
            );
        });
    }

    return (
        <VideoTileWrapper>
            <Header>Video</Header>
            {mainComponent}
            <RecordingsSelectedPopup
                recordings={recordings}
                selectedRecordingIds={new Set(selectedRecordingIds)}
                readOnly={readOnly}
                clearSelectedRecordings={clearRecordings}
                selectAllRecordings={selectAllRecordings}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                logger={appLogger}
            />
        </VideoTileWrapper>
    );
};

const NoRecordings = (): JSX.Element => (
    <NoRecordingsWrapper>No recordings available</NoRecordingsWrapper>
);

const NoRecordingsWrapper = styled(Column)({
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    fontSize: Theme.text.size.medium,
    backgroundColor: Theme.colors.grey01A50,
    minHeight: '5rem',
    fontFamily: Theme.text.bodyFontFamily,
});

const VideoTileWrapper = styled.div({
    flex: 1,
});
