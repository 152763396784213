import { FC } from 'react';
import { useObservable } from '@streem/sdk-react';
import { observer } from 'mobx-react';
import { useDetailSession } from '../../hooks/detail_session_hooks';
import { useGlobalStore } from '../../hooks/use_global_context';
import { CallDetailsToolbar } from './call_details_toolbar';
import { CallDetailsWithTabs } from './tabs/call_detail_with_tabs';
import { CallDetailNoTab } from './reservation/call_detail_no_tab';
import { LegacyCallDetailContent } from './legacy/call_detail_content';

/**
 * Call Details page UI
 */
export const CallDetailsView: FC<{
    readOnly?: boolean;
}> = observer(({ readOnly = false }) => {
    const detailSession = useDetailSession();
    const { companySettingsStore } = useGlobalStore();
    const [room] = useObservable(detailSession.room.room);
    const [favorited] = useObservable(detailSession.personalizations.favorited);

    // There are 3 views now:
    //
    // 1. When call details are enabled and tabs are enabled, show the tabbed call details view
    // 2. When call details are enabled and tabs are disabled, show the call details view
    // 3. When call details are disabled, show the legacy call details view
    //
    // Note: if there are no call details for this call/room, the legacy call details view
    //    will be shown
    const showCallDetailsYn =
        companySettingsStore.ahsVirtualExpertEnabled && room?.callDetail != null;
    const showTabbedCallDetailsYn = showCallDetailsYn && companySettingsStore.callDetailTabsEnabled;

    const contents = showTabbedCallDetailsYn ? (
        <CallDetailsWithTabs readOnly={readOnly} />
    ) : showCallDetailsYn ? (
        <CallDetailNoTab readOnly={readOnly} />
    ) : (
        <LegacyCallDetailContent readOnly={readOnly} />
    );

    return (
        <div style={{ width: '100%' }}>
            <CallDetailsToolbar favorited={favorited} room={room} />
            {contents}
        </div>
    );
});
