import { EditableNote, MarkDownNotes, styled, Theme } from '@streem/ui-react';
import { useCallback, useState } from 'react';
import { useNotes } from '../../../../hooks/use_notes';
import appLogger from '../../../../util/logging/app_logger';
import { scrollIntoViewRefCallback } from '../../../../util/scroll_into_view_ref_callback';
import { CallNotesProps } from '../../legacy/call_details_photos_notes';
import { Header } from './shared_styled_components';

export const CallNotes = ({ readOnly }: { readOnly?: boolean }): JSX.Element => {
    const { detailSession, roomNote, isAnonymousSharePageYn, roomNoteCharLimit } = useNotes();
    const [showMarkdown, setShowMarkdown] = useState(true);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

    const text = roomNote?.text;
    const handleEdit = useCallback(() => {
        setShowMarkdown(false);
    }, []);
    const onSave = async (noteText: string) => {
        setShowMarkdown(true);
        try {
            setErrorMessage(undefined);
            await detailSession.note.saveRoomNote(noteText);
        } catch (error) {
            setErrorMessage('Error attempting to edit call notes.');
            appLogger.error('Error attempting to edit call notes: ', error);
        }
    };

    return (
        <CallNotesView
            showMarkdown={showMarkdown}
            text={text}
            handleEdit={handleEdit}
            isAnonymousSharePage={isAnonymousSharePageYn}
            roomNoteCharLimit={roomNoteCharLimit}
            onSave={onSave}
            readOnly={readOnly}
            errorMessage={errorMessage}
        />
    );
};

const CallNotesView = ({
    showMarkdown,
    text,
    handleEdit,
    isAnonymousSharePage,
    roomNoteCharLimit,
    onSave,
    readOnly,
    errorMessage,
}: CallNotesProps): JSX.Element => {
    // Shows background color from parent container
    const editButtonStyles = { backgroundColor: 'transparent' };
    //
    // In editable_note.tsx, there is a top level div called NotesChrome that has a minimum height of 24px
    //     even when its contents are empty.  I don't know why it is called NotesChrome or why it has a
    //     minimum height of 24px.  However, it was breaking the layout of this component, so the
    //     minHeight is being overridden here.
    const notesChromeStyles = { minHeight: 0 };
    const component =
        showMarkdown && text !== undefined ? (
            <MarkDownNotes
                text={text}
                onEdit={handleEdit}
                showTitle={false}
                readOnly={isAnonymousSharePage}
                editButtonStyles={editButtonStyles}
            />
        ) : (
            <EditableNote
                description="Call Notes"
                placeholderText="Add call notes"
                characterLimit={roomNoteCharLimit}
                noteText={text}
                disableEditing={readOnly}
                onSave={onSave}
                errorMessage={errorMessage}
                hasFootBar={false}
                showChrome={false}
                isEditing={!showMarkdown}
                showTitle={false}
                notesChromeStyles={notesChromeStyles}
                testId="call-notes-editable-note"
            />
        );
    return (
        <>
            <Header ref={scrollIntoViewRefCallback}>Call Notes</Header>
            <CallNotesWrapper>{component}</CallNotesWrapper>
        </>
    );
};

const CallNotesWrapper = styled.div({
    backgroundColor: Theme.colors.grey01A50,
    ':hover': {
        backgroundColor: Theme.colors.hoverBlue,
    },
});
