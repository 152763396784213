import { Grid, styled } from '@streem/ui-react';
import { NotesAndTranscriptions } from './summary/notes_and_transcriptions';
import { VideoAndBookmarks } from './summary/video_and_bookmarks';

export const CallDetailSummaryTab = ({ readOnly }: { readOnly?: boolean }): JSX.Element => {
    return (
        <SummaryTabWrapper data-testid="call-detail-summary-tab">
            <VideoAndBookmarks readOnly={readOnly} />
            <NotesAndTranscriptions readOnly={readOnly} />
        </SummaryTabWrapper>
    );
};

const SummaryTabWrapper = styled(Grid)({
    columnGap: '1.5rem',
    gridTemplateColumns: '3fr 2fr',
});
