import { recordArtifactViewed } from '@streem/analytics';
import { Recording } from '@streem/sdk-core';
import { Column, Row, Skeleton, styled, Theme } from '@streem/ui-react';
import { ReactNode, useState } from 'react';
import { useGlobalStore } from '../../../../hooks/use_global_context';
import { isPreviewReady } from '../../../../util/artifacts';
import { isChrome } from '../../../../util/is_chrome';
import {
    PreviewButton,
    UnsupportedBrowserInfoCard,
    VideoPreviewInfoCard,
} from '../../../media_gallery/media_gallery';
import { RecordingTrackThumbnail } from '../../../media_gallery/thumbnail';

interface PendingVideoProps {
    recordings: Recording[];
    showSelectRecordingYn: boolean;
    roomSid: string;
}
export const PendingVideo = ({
    recordings,
    showSelectRecordingYn,
    roomSid,
}: PendingVideoProps): JSX.Element => {
    const { configStore } = useGlobalStore();
    const [showInfoCardYn, setShowInfoCardYn] = useState(false);
    const [infoCardAcceptedYn, setInfoCardAcceptedYn] = useState(false);

    const browserSupportsPreviewYn = isChrome();
    const previewsReadyYn = isPreviewReady(recordings);
    const onClickPreview = () => setShowInfoCardYn(true);
    const onAcceptInfoCard = () => setInfoCardAcceptedYn(true);
    const onDeclineInfoCard = () => {
        setInfoCardAcceptedYn(false);
        // set this back to false so that the Preview Button is shown again
        setShowInfoCardYn(false);
    };

    return (
        <PendingVideoView
            previewEnabledYn={configStore.videoTrackPreviewEnabled}
            browserSupportsPreviewYn={browserSupportsPreviewYn}
            previewsReadyYn={previewsReadyYn}
            showPreviewInfoCardYn={showInfoCardYn}
            onClickPreview={onClickPreview}
            infoCardAcceptedYn={infoCardAcceptedYn}
            onAcceptInfoCard={onAcceptInfoCard}
            onDeclineInfoCard={onDeclineInfoCard}
            recordings={recordings}
            roomSid={roomSid}
            showSelectRecordingYn={showSelectRecordingYn}
        />
    );
};

interface PendingVideoViewProps {
    previewEnabledYn: boolean;
    browserSupportsPreviewYn: boolean;
    previewsReadyYn: boolean;
    showPreviewInfoCardYn: boolean;
    onClickPreview: () => void;
    infoCardAcceptedYn: boolean;
    onAcceptInfoCard: () => void;
    onDeclineInfoCard: () => void;
    recordings: Recording[];
    roomSid: string;
    showSelectRecordingYn: boolean;
}
const PendingVideoView = ({
    previewEnabledYn,
    browserSupportsPreviewYn,
    previewsReadyYn,
    showPreviewInfoCardYn,
    onClickPreview,
    infoCardAcceptedYn,
    onAcceptInfoCard,
    onDeclineInfoCard,
    recordings,
    roomSid,
    showSelectRecordingYn,
}: PendingVideoViewProps): JSX.Element => {
    // For previewing a video, sequence is as follows:
    //   1. Show preview button
    //   2. If user clicks on preview button, show info card
    //   3. If user clicks OK on info card, show video previews

    let innerComponent: ReactNode;
    // if previews are not enabled or not ready, show an empty skeleton
    if (!previewEnabledYn || !previewsReadyYn) {
        innerComponent = <Skeleton data-testid="empty-skeleton" />;
    } else if (!browserSupportsPreviewYn) {
        innerComponent = <UnsupportedBrowserInfoCard data-testid="unsupported-browser" />;
    } else if (!showPreviewInfoCardYn) {
        innerComponent = <PreviewButton onClick={onClickPreview} data-testid="preview-button" />;
    } else if (!infoCardAcceptedYn) {
        innerComponent = (
            <VideoPreviewInfoCard
                handleOkay={onAcceptInfoCard}
                handleCancel={onDeclineInfoCard}
                Container={InfoCardContainer}
                data-testid="preview-info-card"
            />
        );
    } else {
        // show video previews
        innerComponent = recordings.map(recording => (
            <RecordingTrackThumbnail
                canSelect={false}
                isSelected={false}
                isSelectVisible={showSelectRecordingYn}
                key={`preview-${recording.id}`}
                artifact={recording}
                onPlay={() =>
                    recordArtifactViewed({
                        artifactSid: recording.id,
                        roomSid,
                        recordingTrack: {},
                    })
                }
                data-testid="recording-track-thumbnail"
            />
        ));
    }

    // if we made it to this point, previews are enabled and ready and the user has accepted the info card
    return <VideoContainer data-testid="video-container">{innerComponent}</VideoContainer>;
};

const VideoContainer = styled(Row)({
    backgroundColor: Theme.colors.grey01A50,
    alignItems: 'center',
    justifyContent: 'center',
    height: '25rem',
});

export const InfoCardContainer = styled(Column)({
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1rem',
});
